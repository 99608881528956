import Td from "./Td";
// import Forword from "./Forword";
// import Edit from "./Edit";

export default function DataRow({ student, keyIndex, setSelected, selected }) {
  return (
    <tr
      className={selected === student.id ? "bg-blue-300" : "hover:bg-gray-300"}
      onClick={() => {
        setSelected(student.id);
      }}
      key={keyIndex}
    >
      <Td text={student.unique_id} key={keyIndex + 1} />
      <Td text={student.id} key={keyIndex + 2} />
      <Td text={student.passport_number} key={keyIndex + 3} />
      <Td text={student.full_name} key={keyIndex + 4} />
      <Td text={student.first_name} key={keyIndex + 5} />
      <Td text={student.middle1_name} key={keyIndex + 6} />
      <Td text={student.middle2_name} key={keyIndex + 7} />
      <Td text={student.last_name} key={keyIndex + 8} />
      <Td text={student.email} key={keyIndex + 9} />
      <Td text={student.address} key={keyIndex + 10} />
      <Td text={student.postal_code} key={keyIndex + 11} />
      <Td text={student.date_of_birth} key={keyIndex + 12} />
      <Td text={student.school_name} key={keyIndex + 13} />
      <Td text={student.test_center_name} key={keyIndex + 14} />
      <Td text={student.phone} key={keyIndex + 15} />
      <Td text={student.gender} key={keyIndex + 16} />
      <Td text={student.university} key={keyIndex + 17} />
      <Td text={student.collegeName1} key={keyIndex + 18} />
      <Td text={student.colabbreviation1} key={keyIndex + 19} />
      <Td text={student.collegeName2} key={keyIndex + 20} />
      <Td text={student.colabbreviation2} key={keyIndex + 21} />
      <Td text={student.collegeName3} key={keyIndex + 22} />
      <Td text={student.colabbreviation3} key={keyIndex + 23} />
      <Td text={student.collegeName4} key={keyIndex + 24} />
      <Td text={student.colabbreviation4} key={keyIndex + 25} />
      <Td text={student.collegeName5} key={keyIndex + 26} />
      <Td text={student.colabbreviation5} key={keyIndex + 27} />
      <Td text={student.specialization1} key={keyIndex + 28} />
      <Td text={student.specialization2} key={keyIndex + 29} />
      <Td text={student.specialization3} key={keyIndex + 30} />
      <Td text={student.studentyear} key={keyIndex + 31} />
      <Td text={student.schoolNum} key={keyIndex + 32} />
      <Td text={student.category} key={keyIndex + 33} />
      {/*------------------------ { exam1 } ------------------------- */}
      <Td text={student.act1_id} key={keyIndex + 34} />
      <Td text={student.exam_name} key={keyIndex + 35} />
      <Td text={student.act1_score1} key={keyIndex + 36} />
      <Td text={student.act1_score2} key={keyIndex + 37} />
      <Td text={student.act1_score3} key={keyIndex + 38} />
      <Td text={student.act1_try_date1} key={keyIndex + 39} />
      <Td text={student.act1_try_date2} key={keyIndex + 40} />
      <Td text={student.act1_try_date3} key={keyIndex + 41} />
      <Td text={student.act1_try_date4} key={keyIndex + 42} />
      <Td text={student.act1_try_date5} key={keyIndex + 43} />
      <Td text={student.act1_copy_number} key={keyIndex + 44} />
      <Td text={student.act1_moe_copy} key={keyIndex + 45} />
      <Td text={student.act1_try1_id} key={keyIndex + 46} />
      <Td text={student.act1_try2_id} key={keyIndex + 47} />
      <Td text={student.act1_try3_id} key={keyIndex + 48} />
      <Td text={student.act1_try4_id} key={keyIndex + 49} />
      <Td text={student.act1_try5_id} key={keyIndex + 50} />
      {/*------------------------ { exam2 } ------------------------- */}
      <Td text={student.act2_id} key={keyIndex + 51} />
      <Td text={student.act2_score1} key={keyIndex + 52} />
      <Td text={student.act2_score2} key={keyIndex + 53} />
      <Td text={student.act2_score3} key={keyIndex + 54} />
      <Td text={student.act2_try_date1} key={keyIndex + 55} />
      <Td text={student.act2_try_date2} key={keyIndex + 56} />
      <Td text={student.act2_try_date3} key={keyIndex + 57} />
      <Td text={student.subject1} key={keyIndex + 58} />
      <Td text={student.subject2} key={keyIndex + 59} />
      <Td text={student.subject3} key={keyIndex + 60} />
      <Td text={student.act2_copy_number} key={keyIndex + 61} />
      <Td text={student.act2_moe_copy} key={keyIndex + 62} />
      <Td text={student.act2_try1_id} key={keyIndex + 63} />
      <Td text={student.act2_try2_id} key={keyIndex + 64} />
      <Td text={student.act2_try3_id} key={keyIndex + 65} />
      {/*------------------------ { state & dates } ------------------------- */}
      <Td text={student.state} key={keyIndex + 66} />
      <Td text={student.invoice_number} key={keyIndex + 67} />
      <Td text={student.another_invoice_number} key={keyIndex + 68} />
      <Td text={student.score_pending} key={keyIndex + 69} />
      <Td text={student.moe_pending} key={keyIndex + 70} />
      <Td text={student.request_edit} key={keyIndex + 71} />
      <Td text={student.submit_date} key={keyIndex + 72} />
      <Td text={student.request_edit_date} key={keyIndex + 73} />
      <Td text={student.request_edit_user} key={keyIndex + 74} />
      <Td text={student.invoice_date} key={keyIndex + 75} />
      <Td text={student.invoice_user} key={keyIndex + 76} />
      <Td text={student.invoice_wait_date} key={keyIndex + 77} />
      <Td text={student.invoice_wait_user} key={keyIndex + 78} />
      <Td text={student.another_invoice_date} key={keyIndex + 79} />
      <Td text={student.another_invoice_user} key={keyIndex + 80} />
      <Td text={student.score_validation_date} key={keyIndex + 81} />
      <Td text={student.score_validation_user} key={keyIndex + 82} />
      <Td text={student.score_request_date} key={keyIndex + 83} />
      <Td text={student.score_request_user} key={keyIndex + 84} />
      <Td text={student.score_pending_date} key={keyIndex + 85} />
      <Td text={student.score_pending_user} key={keyIndex + 86} />
      <Td text={student.print_date} key={keyIndex + 87} />
      <Td text={student.print_user} key={keyIndex + 88} />
      <Td text={student.print_validation_date} key={keyIndex + 89} />
      <Td text={student.print_validation_user} key={keyIndex + 90} />
      <Td text={student.moe_validation_date} key={keyIndex + 91} />
      <Td text={student.moe_validation_user} key={keyIndex + 92} />
      <Td text={student.moe_date} key={keyIndex + 93} />
      <Td text={student.moe_user} key={keyIndex + 94} />
      <Td text={student.moe_pending_date} key={keyIndex + 95} />
      <Td text={student.moe_pending_user} key={keyIndex + 96} />
      <Td text={student.deliver_date} key={keyIndex + 97} />
      <Td text={student.deliver_user} key={keyIndex + 98} />
      <Td text={student.mohe_validation_date} key={keyIndex + 99} />
      <Td text={student.mohe_validation_user} key={keyIndex + 100} />
      <Td text={student.mohe_date} key={keyIndex + 101} />
      <Td text={student.mohe_user} key={keyIndex + 102} />
      <Td text={student.done_date} key={keyIndex + 103} />
      <Td text={student.done_user} key={keyIndex + 104} />
      <Td text={student.total_main} key={keyIndex + 105} />
      <Td text={student.another_total_main} key={keyIndex + 106} />
      <Td text={student.amount_outside} key={keyIndex + 107} />
    </tr>
  );
}

//<Edit state={student.state} id={student.id} />
//<Forword state={student.state} id={student.id} />
