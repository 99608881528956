import React from "react";

function UNAUTHORIZED() {
  return (
    <div className=" flex items-center pt-40 justify-center">
      <div className="flex flex-col text-white bg-red-700 border rounded-lg p-7 shadow bg-white">
        <p>UNAUTHORIZED</p>
      </div>
    </div>
  );
}

export default UNAUTHORIZED;
