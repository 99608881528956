import { useState } from "react";
import { deleteStudent } from "../../actions/studensts";

import AlertDenger from "../../components/AlertDenger";
import AlertSuccess from "../../components/AlertSuccess";
import Loading from "../../components/Loading";

export default function Modal({ selected, setShow }) {
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showDengerAlert, setShowDengerAlert] = useState(false);
  const [text, setText] = useState("");
  const [loading, setLoading] = useState(false);

  const handleClick = async () => {
    setLoading(true);
    console.log(selected);
    const res = await deleteStudent(selected);

    if (res.status === 200) {
      setShowSuccessAlert(true);
      setText(res.data.message);
    } else {
      setShowDengerAlert(true);
      setText(res.data.ERROR);
    }
  };

  if (showSuccessAlert || showDengerAlert) {
    setTimeout(function () {
      setShowSuccessAlert(false);
      setShowDengerAlert(false);
      setLoading(false);
      setShow(false);
    }, 1000);
  }

  return (
    <div
      className="flex overflow-y-auto overflow-x-hidden fixed right-0 left-0 
    top-4 z-40 justify-center items-center md:inset-0 h-modal sm:h-full"
    >
      {showSuccessAlert && <AlertSuccess text={text} />}
      {showDengerAlert && <AlertDenger text={text} />}
      <div className="relative px-4 w-full max-w-md h-full md:h-auto">
        <div className="relative bg-white rounded-lg shadow border">
          <div className="flex justify-end p-2">
            <button
              type="button"
              className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
              onClick={() => setShow(false)}
            >
              <svg
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </button>
          </div>
          <div className="p-6 pt-0 text-center">
            <svg
              className="mx-auto mb-4 w-14 h-14 text-gray-400"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              ></path>
            </svg>
            <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
              Are you sure you want to{" "}
              <span className="text-red-500 font-bold">DELETE</span> this
              student?
            </h3>
            <button
              type="button"
              className="text-white bg-red-600 hover:bg-red-800 focus:ring-4
    focus:ring-red-300 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2"
              onClick={handleClick}
              disabled={loading}
            >
              {!loading ? "Yes, I'm sure" : <Loading />}
            </button>
            <button
              type="button"
              className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 
    focus:ring-gray-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 "
              onClick={() => setShow(false)}
            >
              No, cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
