import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import * as actionType from "../../constants/actionTypes";
import { getAll } from "../../actions/studensts";
import { tokenExpired } from "../token";

import Table from "./Table";
import Modal from "./Modal";
import JumpToModal from "./JumpToModal";
import Pagination from "./Pagination";
import Header from "./Header";
import ChagneCategory from "./ChagneCategory";
import { exportToExcel } from "../exportToExcel";
import UNAUTHORIZED from "../UNAUTHORIZED";

export default function Index() {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("profile")));
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { students, count } = useSelector((state) => state.students);
  const [page, setPage] = useState(1);
  const [nextDisabled, setNextDisabled] = useState(false);
  const [search, setSearch] = useState("");
  const [edit, setEdit] = useState("");
  const [loading, setLoading] = useState(true);

  const [selected, setSelected] = useState();
  const [selectState, setSelectState] = useState("all");
  const [showPop, setShowPop] = useState(false);
  const [showJumpToPop, setShowJumpToPop] = useState(false);
  const [showChangeCategory, setShowChangeCategory] = useState(false);

  const logout = useCallback(() => {
    dispatch({ type: actionType.LOGOUT });
    navigate("/");
  }, [dispatch, navigate]);

  //the requested edit students
  const onChangeEdit = (e) => {
    if (e.target.checked) {
      setEdit("edit");
      return;
    }
    setEdit("");
  };

  // new 15/8/2022 export to excel after select state and filter students
  const getSelectState = (state) => {
    let newSelected = selectState;
    newSelected = state;
    setSelectState(newSelected);
  };

  // update students on search, edit requests or change pages
  useEffect(() => {
    tokenExpired(user, logout, navigate);
    // wait for typing the full text in the search
    const timeOutId = setTimeout(
      () => dispatch(getAll(page, search, edit)),
      500,
    );
    setUser(user);
    return () => clearTimeout(timeOutId);
  }, [page, search, edit, logout, navigate, dispatch, user]);

  // disabled the next button if in last page
  useEffect(() => {
    if (students) {
      setLoading(false);
    } else {
      setLoading(true);
    }

    if (page === count?.maxPage) {
      setNextDisabled(true);
    } else {
      setNextDisabled(false);
    }
  }, [page, count?.maxPage, students]);

  return (
    <div>
      {user.value[0].role !== "admin" ? (
        <UNAUTHORIZED />
      ) : (
        <div>
          <h1 className="text-2xl pt-0 pl-6 pb-5 text-white">Admin Table</h1>
          <div>
            {showPop && <Modal selected={selected} setShow={setShowPop} />}
            {showJumpToPop && (
              <JumpToModal selected={selected} setShow={setShowJumpToPop} />
            )}
            {showChangeCategory && (
              <ChagneCategory
                selected={selected}
                setShow={setShowChangeCategory}
              />
            )}
            <div className="p-3">
              <Header
                selected={selected}
                setShowJumpToPop={setShowJumpToPop}
                setShowPop={setShowPop}
                setShowChangeCategory={setShowChangeCategory}
                onChangeEdit={onChangeEdit}
                setSearch={setSearch}
                setPage={setPage}
                getSelectState={getSelectState}
                exportState={() => exportToExcel(selectState)}
              />
            </div>
            <div className="overflow-auto p-3">
              {loading ? (
                <div className="text-center text-white p-20 text-2xl">
                  Wait, it may take a few seconds to loading...
                </div>
              ) : (
                <Table
                  selected={selected}
                  setSelected={setSelected}
                  students={students}
                />
              )}
            </div>
            <Pagination
              page={page}
              setPage={setPage}
              maxPage={count?.maxPage}
              nextDisabled={nextDisabled}
            />
          </div>
        </div>
      )}
    </div>
  );
}
