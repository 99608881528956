import { useNavigate, useParams } from "react-router-dom";
import Button from "../../components/Button";
import { forword } from "../../actions/studensts";
import AlertSuccess from "../../components/AlertSuccess";
import AlertDenger from "../../components/AlertDenger";
import Loading from "../../components/Loading";
import { useState } from "react";

export default function ActionButtonsPending() {
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showDengerAlert, setShowDengerAlert] = useState(false);
  const [text, setText] = useState("");
  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);

  const navigate = useNavigate();
  const { id } = useParams();

  if (showSuccessAlert || showDengerAlert) {
    setTimeout(function () {
      setShowSuccessAlert(false);
      setShowDengerAlert(false);
      setLoading1(false);
      setLoading2(false);
      // navigate("/main/moe/moe_pending");
    }, 2000);
  }

  const handleForward1 = async () => {
    setLoading1(true);
    const res = await forword(
      id,
      "moe/",
      "deliver_onsite",
      JSON.parse(localStorage.getItem("profile"))?.value[0].username,
    );
    if (res.status === 200) {
      setShowSuccessAlert(true);
      setText(res.data.message);
    } else {
      setShowDengerAlert(true);
      setText(res.data.ERROR);
    }
  };

  const handleForward2 = async () => {
    setLoading2(true);
    const res = await forword(
      id,
      "moe/",
      "aramex",
      JSON.parse(localStorage.getItem("profile"))?.value[0].username,
    );
    if (res.status === 200) {
      setShowSuccessAlert(true);
      setText(res.data.message);
    } else {
      setShowDengerAlert(true);
      setText(res.data.ERROR);
    }
  };

  return (
    <div className="flex justify-between">
      {showSuccessAlert && <AlertSuccess text={text} />}
      {showDengerAlert && <AlertDenger text={text} />}
      <div className="p-2">
        <Button
          color="bg-green-500 disabled:bg-gray-300"
          onClick={handleForward1}
          disabled={loading1}
          text={!loading1 ? "Forward to Deliver Onsite" : <Loading />}
        />
      </div>
      <div className="p-2">
        <Button
          color="bg-green-500 disabled:bg-gray-300"
          onClick={handleForward2}
          disabled={loading2}
          text={!loading2 ? "Forward to Deliver By Aramex" : <Loading />}
        />
      </div>
      <div className="p-2">
        <Button
          color="bg-green-700"
          onClick={() => {
            navigate("/main/moe/moe_pending/comment", { state: id });
          }}
          text="Comment"
        />
      </div>
    </div>
  );
}
