import React from "react";

export default function Button({
  text,
  onClick,
  color,
  type = "",
  disabled = false,
}) {
  return (
    <button
      className={
        color + " py-2.5 px-5 text-sm rounded-lg text-white active:scale-90"
      }
      onClick={onClick}
      type={type}
      disabled={disabled}
    >
      {text}
    </button>
  );
}
