import decode from "jwt-decode";

export function tokenExpired(user, logout, navigate) {
  // if not login back to login page
  if (!user) {
    navigate("/");
    return;
  }
  // check if token exist or expired
  const token = user.value[0].token;
  if (!token) {
    logout();
    return;
  }
  const decodedToken = decode(token);
  if (decodedToken.exp * 1000 < new Date().getTime()) {
    logout();
  }
}
