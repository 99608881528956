import { useEffect, useState } from "react";
import { getAll, Amount } from "../actions/studensts";
import { useDispatch, useSelector } from "react-redux";
import Table from "./Table/Table";
import Pagination from "./Table/Pagination";
import Search from "./Table/Search";
import { exportToExcel } from "./exportToExcel";

function Main() {
  const { students, count } = useSelector((state) => state.students);
  const [selected, setSelected] = useState(false);
  const [page, setPage] = useState(1);
  const [nextDisabled, setNextDisabled] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loading1, setLoading1] = useState(false);
  const [search, setSearch] = useState("");
  const [dailyAmount, setDailyAmount] = useState(0);
  const [allAmount, setAllAmount] = useState(0);
  const [selectState, setSelectState] = useState("all");
  const dispatch = useDispatch();
  const user = JSON.parse(localStorage.getItem("profile")).value[0];
  const getSelectState = (state) => {
    let newSelected = selectState;
    newSelected = state;
    setSelectState(newSelected);
  };

  const getDailyAmount = async () => {
    setLoading1(true);
    const { data } = await Amount();
    if (data) {
      setLoading1(false);
      setDailyAmount(data.daily_amount);
      setAllAmount(data.total_amount);
    }
  };

  // update students on search requests or change pages
  useEffect(() => {
    // wait for typing the full text in the search
    const timeOutId = setTimeout(() => dispatch(getAll(page, search)), 500);
    return () => clearTimeout(timeOutId);
  }, [page, search, dispatch]);

  // disabled the next button if in last page
  useEffect(() => {
    getDailyAmount();
    if (students) {
      setLoading(false);
    } else {
      setLoading(true);
    }
    if (page === count?.maxPage) {
      setNextDisabled(true);
    } else {
      setNextDisabled(false);
    }
  }, [count?.maxPage, page, students, dailyAmount, allAmount]);

  return (
    <div>
      <div className="flex flex-wrap justify-between p-5">
        <h1 className="text-2xl p-3 text-white">Main Page</h1>
        <div className="flex flex-wrap justify-between">
          {user.role === "invoice" || user.username === "admin" ? (
            <div className="p-3">
              <h2 className="text-xl bg-green-700 p-3 rounded  text-white">
                Daily Amount:{" "}
                <span className="text-xl px-2 rounded bg-white text-red-800">
                  {loading1 ? "Loading..." : dailyAmount}
                </span>
              </h2>
            </div>
          ) : (
            ""
          )}
          {user.role === "invoice" || user.username === "admin" ? (
            <div className="p-3">
              <h2 className="text-xl bg-green-700 p-3 rounded  text-white">
                Total Amount:{" "}
                <span className="text-xl px-2 rounded bg-white text-red-800">
                  {loading1 ? "Loading..." : allAmount}
                </span>
              </h2>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      <div>
        <div className="w-80 m-auto">
          {(user.username === "asmaa" || user.username === "abdelrhman"|| user.username === "nour") && (
            <div className="p-3 w-72 flex justify-between">
              <div>
                <div>
                  <label className="fs-4 fw-normal text-white" htmlFor="states">
                    Select a State to Export:{" "}
                  </label>
                </div>
                <select
                  onChange={(e) => getSelectState(e.target.value)}
                  className="w-40 p-1"
                  id="states"
                >
                  <option value="all">all</option>
                  <option value="auditing">auditing</option>
                  <option value="invoice">invoice</option>
                  <option value="invoice_wait">invoice_wait</option>
                  <option value="another_invoice">another_invoice</option>
                  <option value="request_edit">request_edit</option>
                  <option value="score_validation">score_validation</option>
                  <option value="score_request">score_request</option>
                  <option value="score_pending">score_pending</option>
                  <option value="print">print</option>
                  <option value="print_validation">print_validation</option>
                  <option value="moe_validation">moe_validation</option>
                  <option value="moe">moe</option>
                  <option value="moe_pending">moe_pending</option>
                  <option value="deliver_onsite">deliver_onsite</option>
                  <option value="aramex">aramex</option>
                  <option value="mohe_validation">mohe_validation</option>
                  <option value="mohe">mohe</option>
                  <option value="done">done</option>
                </select>
              </div>
              <button
                type="button"
                className="text-white bg-green-900 hover:bg-green-800 p-2 
                        focus:ring-4 focus:ring-green-400 font-medium rounded-lg text-sm text-center cursor-pointer"
                onClick={() => exportToExcel(selectState)}
              >
                Export
              </button>
            </div>
          )}
          <Search setSearch={setSearch} setPage={setPage} />
        </div>
        <div className="overflow-auto p-3">
          {loading ? (
            <div className="text-center text-white p-20 text-2xl">
              Wait, it may take a few seconds to loading...
            </div>
          ) : (
            <Table
              selected={selected}
              setSelected={setSelected}
              students={students}
            />
          )}
        </div>
        <div className="py-4">
          <Pagination
            page={page}
            setPage={setPage}
            maxPage={count?.maxPage}
            nextDisabled={nextDisabled}
          />
        </div>
      </div>
    </div>
  );
}

export default Main;
