import React, { useCallback, useState, useEffect } from "react";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import * as IoIcons from "react-icons/io";
import * as BsIcons from "react-icons/bs";
import * as MdIcons from "react-icons/md";
import { NavLink, Outlet, useLocation, useNavigate } from "react-router-dom";
import * as actionType from "../../constants/actionTypes";
import "./Navbar.css";
import { IconContext } from "react-icons";
import { useDispatch } from "react-redux";
import { tokenExpired } from "../token";
import Button from "../../components/Button";
import UNAUTHORIZED from "../UNAUTHORIZED";

function Sidebar() {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("profile")));
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [sidebar, setSidebar] = useState(false);
  const [dropMenuInv, setDropMenuInv] = useState(false);
  const [dropMenuAud, setDropMenuAud] = useState(false);
  const [dropMenuScore, setDropMenuScore] = useState(false);
  const [dropMenuPrint, setDropMenuPrint] = useState(false);
  const [dropMenuMoe, setDropMenuMoe] = useState(false);
  const [dropMenuDeliver, setDropMenuDeliver] = useState(false);
  const [dropMenuMOHE, setDropMenuMOHE] = useState(false);

  const showSidebar = () => setSidebar(!sidebar);
  const showDropMenuInv = () => setDropMenuInv(!dropMenuInv);
  const showDropMenuAud = () => setDropMenuAud(!dropMenuAud);
  const showDropMenuScore = () => setDropMenuScore(!dropMenuScore);
  const showDropMenuPrint = () => setDropMenuPrint(!dropMenuPrint);
  const showDropMenuMoe = () => setDropMenuMoe(!dropMenuMoe);
  const showDropMenuDeliver = () => setDropMenuDeliver(!dropMenuDeliver);
  const showDropMenuMOHE = () => setDropMenuMOHE(!dropMenuMOHE);

  const logout = useCallback(() => {
    dispatch({ type: actionType.LOGOUT });
    navigate("/");
  }, [dispatch, navigate]);

  useEffect(() => {
    tokenExpired(user, logout, navigate);
    setUser((user) => user);
  }, [location, user, navigate, logout]);

  return (
    <div className="mb-10">
      {!user ? (
        <UNAUTHORIZED />
      ) : (
        <div>
          <IconContext.Provider value={{ color: "#fff" }}>
            <div className="navbar">
              <div className="menu-bars cursor-pointer">
                <FaIcons.FaBars onClick={showSidebar} />
              </div>
              <div className="px-8">
                <Button
                  text="Logout"
                  onClick={logout}
                  color="bg-red-600 hover:bg-red-700"
                />
              </div>
            </div>
            <nav className={sidebar ? "nav-menu active" : "nav-menu"}>
              <div className="overflow-scroll w-full">
                <ul className="nav-menu-items">
                  <li className="navbar-toggle" onClick={showSidebar}>
                    <div className="menu-bars cursor-pointer">
                      <AiIcons.AiOutlineClose />
                    </div>
                  </li>
                  <li className="nav-text" onClick={showSidebar}>
                    <NavLink to="/main">
                      <AiIcons.AiFillHome />
                      <span>Main</span>
                    </NavLink>
                  </li>
                  {user.value[0].role === "admin" ? (
                    <>
                      <li className="nav-text" onClick={showSidebar}>
                        <NavLink to="/main/overview">
                          <IoIcons.IoIosPaper />
                          <span>Overview</span>
                        </NavLink>
                      </li>
                      <li className="nav-text" onClick={showSidebar}>
                        <NavLink to="/main/table">
                          <BsIcons.BsTable />
                          <span>Table</span>
                        </NavLink>
                      </li>
                      <li className="nav-text" onClick={showSidebar}>
                        <NavLink to="/main/actions">
                          <IoIcons.IoMdPeople />
                          <span>Global Actions</span>
                        </NavLink>
                      </li>
                    </>
                  ) : (
                    ""
                  )}
                  {user.value[0].username === "admin" ? (
                    <>
                      <li className="nav-text" onClick={showSidebar}>
                        <NavLink to="/main/add_user">
                          <FaIcons.FaUserPlus />
                          <span>Create User</span>
                        </NavLink>
                      </li>
                    </>
                  ) : (
                    ""
                  )}
                  {user.value[0].role === "user" ||
                  user.value[0].role === "admin" ? (
                    <>
                      <li className="nav-text" onClick={showDropMenuAud}>
                        <div className="drop-click">
                          <FaIcons.FaEdit />
                          <span>Auditing</span>
                        </div>
                      </li>
                      {dropMenuAud ? (
                        <div>
                          <li className="nav-text" onClick={showSidebar}>
                            <NavLink to="/main/auditing/new">
                              <span>New</span>
                            </NavLink>
                          </li>
                          {user.value[0].role === "admin" ? (
                            <li className="nav-text" onClick={showSidebar}>
                              <NavLink to="/main/auditing/request_edit">
                                <span>Request Edit</span>
                              </NavLink>
                            </li>
                          ) : (
                            ""
                          )}
                        </div>
                      ) : (
                        ""
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {user.value[0].role === "user" ||
                  user.value[0].role === "invoice" ||
                  user.value[0].role === "admin" ? (
                    <>
                      <li className="nav-text" onClick={showDropMenuInv}>
                        <div className="drop-click">
                          <FaIcons.FaFileInvoiceDollar />
                          <span>Invoice</span>
                        </div>
                      </li>
                      {dropMenuInv ? (
                        <div>
                          <li className="nav-text" onClick={showSidebar}>
                            <NavLink to="/main/invoice/new">
                              <span>New</span>
                            </NavLink>
                          </li>
                          <li className="nav-text" onClick={showSidebar}>
                            <NavLink to="/main/invoice/wait">
                              <span>Invoice Wait</span>
                            </NavLink>
                          </li>
                          {user.value[0].role === "invoice" ||
                          user.value[0].role === "admin" ? (
                            <li className="nav-text" onClick={showSidebar}>
                              <NavLink to="/main/invoice/another_invoice">
                                <span>Another Invoice</span>
                              </NavLink>
                            </li>
                          ) : (
                            ""
                          )}
                        </div>
                      ) : (
                        ""
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {user.value[0].role === "user" ||
                  user.value[0].role === "admin" ? (
                    <>
                      <li className="nav-text" onClick={showDropMenuScore}>
                        <div className="drop-click">
                          <MdIcons.MdScore />
                          <span>Score Handler</span>
                        </div>
                      </li>
                      {dropMenuScore ? (
                        <div>
                          <li className="nav-text" onClick={showSidebar}>
                            <NavLink to="/main/score/score_validation">
                              <span>Score Validation</span>
                            </NavLink>
                          </li>
                          <li className="nav-text" onClick={showSidebar}>
                            <NavLink to="/main/score/new">
                              <span>Score Request</span>
                            </NavLink>
                          </li>
                          <li className="nav-text" onClick={showSidebar}>
                            <NavLink to="/main/score/score_pending">
                              <span>Score Pending</span>
                            </NavLink>
                          </li>
                        </div>
                      ) : (
                        ""
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {user.value[0].role === "user" ||
                  user.value[0].role === "admin" ? (
                    <>
                      <li className="nav-text" onClick={showDropMenuPrint}>
                        <div className="drop-click">
                          <MdIcons.MdPrint />
                          <span>Print</span>
                        </div>
                      </li>
                      {dropMenuPrint ? (
                        <div>
                          <li className="nav-text" onClick={showSidebar}>
                            <NavLink to="/main/print/new">
                              <span>Print</span>
                            </NavLink>
                          </li>
                          <li className="nav-text" onClick={showSidebar}>
                            <NavLink to="/main/print/print_validation">
                              <span>Print Validation</span>
                            </NavLink>
                          </li>
                        </div>
                      ) : (
                        ""
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {user.value[0].role === "user" ||
                  user.value[0].role === "admin" ? (
                    <>
                      <li className="nav-text" onClick={showDropMenuMoe}>
                        <div className="drop-click">
                          <IoIcons.IoIosDocument />
                          <span>MOE</span>
                        </div>
                      </li>
                      {dropMenuMoe ? (
                        <div>
                          <li className="nav-text" onClick={showSidebar}>
                            <NavLink to="/main/moe/moe_validation">
                              <span>MOE Validation</span>
                            </NavLink>
                          </li>
                          <li className="nav-text" onClick={showSidebar}>
                            <NavLink to="/main/moe/new">
                              <span>Sending to MOE</span>
                            </NavLink>
                          </li>
                          <li className="nav-text" onClick={showSidebar}>
                            <NavLink to="/main/moe/moe_pending">
                              <span>Moe Pending</span>
                            </NavLink>
                          </li>
                        </div>
                      ) : (
                        ""
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {user.value[0].role === "user" ||
                  user.value[0].role === "admin" ? (
                    <>
                      <li className="nav-text" onClick={showDropMenuDeliver}>
                        <div className="drop-click">
                          <MdIcons.MdDeliveryDining />
                          <span>Delivery</span>
                        </div>
                      </li>
                      {dropMenuDeliver ? (
                        <div>
                          <li className="nav-text" onClick={showSidebar}>
                            <NavLink to="/main/deliver/deliver_onsite">
                              <span>Delivery Onsite</span>
                            </NavLink>
                          </li>
                          <li className="nav-text" onClick={showSidebar}>
                            <NavLink to="/main/deliver/aramex">
                              <span>Aramex</span>
                            </NavLink>
                          </li>
                        </div>
                      ) : (
                        ""
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {user.value[0].role === "user" ||
                  user.value[0].role === "admin" ? (
                    <>
                      <li className="nav-text" onClick={showDropMenuMOHE}>
                        <div className="drop-click">
                          <IoIcons.IoIosDocument />
                          <span>MOHE</span>
                        </div>
                      </li>
                      {dropMenuMOHE ? (
                        <div>
                          <li className="nav-text" onClick={showSidebar}>
                            <NavLink to="/main/mohe/mohe_validation">
                              <span>MOHE Validation</span>
                            </NavLink>
                          </li>
                          <li className="nav-text" onClick={showSidebar}>
                            <NavLink to="/main/mohe/new">
                              <span>Sending to MOHE</span>
                            </NavLink>
                          </li>
                        </div>
                      ) : (
                        ""
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {user.value[0].role === "user" ||
                  user.value[0].role === "admin" ? (
                    <li className="nav-text" onClick={showSidebar}>
                      <NavLink to="/main/done">
                        <MdIcons.MdOutlineDoneOutline />
                        <span>Done Students</span>
                      </NavLink>
                    </li>
                  ) : (
                    ""
                    )}
                     <li className="nav-text" onClick={showSidebar}>
                      <NavLink to="/form" target="_blank">
                        <MdIcons.MdAddBox />
                        <span>Form Page</span>
                      </NavLink>
                    </li>
                </ul>
              </div>
            </nav>
          </IconContext.Provider>

          <main className="py-5">
            <div>
              <Outlet />
            </div>
          </main>
          <div className="footer-style">
            <p>American Academy&copy; v3.0</p>
          </div>
        </div>
      )}
    </div>
  );
}

export default Sidebar;
