export default function Search({ setSearch, setPage }) {
  const onChange = (e) => {
    setSearch(e.target.value);
    setPage(1);
  };

  return (
    <div >
      <input
        type="search"
        className="bg-gray-50 border-2 border-orange-400 h-10 pr-5 pl-10 rounded-full text-sm 
                focus:outline-none focus:shadow focus:bg-white"
        placeholder="search..."
        onChange={onChange}
      />

    </div>
  );
}
