import { heads } from "./config";
import Th from "./Th";
import DataRow from "./DataRow";

export default function Table({ students, setSelected, selected }) {
  return (
    <div className="border rounded-lg box-border bg-white mt-2">
      <div className="overflow-auto">
        <div className="table rounded-lg h-fit bg-white">
          <table className="divide-y divide-gray-500 table-fixed">
            <thead className="bg-gray-200">
              <tr>
                {heads.map((h, i) => (
                  <Th bgColor={h.bgColor} text={h.text} key={i} />
                ))}
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-400">
              {students?.map((student, i) => (
                <DataRow
                  student={student}
                  keyIndex={i}
                  setSelected={setSelected}
                  selected={selected}
                  key={i + 56}
                />
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
