export default function RedirectCard({ request }) {
  return (
    <div className="justify-center items-center md:inset-0 h-modal sm:h-full">
      <div className="relative px-4 w-full max-w-md h-full md:h-auto">
        <div className="bg-white rounded-lg shadow">
          <div className="p-6 text-center">
            {request === "good" ? <GoodRequest /> : <BadRequest />}
            {request === "good" ? <GoodRequestText /> : <BadRequestText />}
          </div>
        </div>
      </div>
    </div>
  );
}

function BadRequest() {
  return (
    <svg
      className="mx-auto mb-4 w-14 h-14 text-red-400"
      fill="none"
      stroke="currentColor"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
      />
    </svg>
  );
}

function GoodRequest() {
  return (
    <svg
      className="mx-auto mb-4 w-14 h-14 text-green-400"
      fill="none"
      stroke="currentColor"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
      />
    </svg>
  );
}

function GoodRequestText() {
  return (
    <h3 className="mb-5 text-lg font-normal text-gray-500 ">
      We received your request and you will receive a mail from us as soon as
      possible.
    </h3>
  );
}

function BadRequestText() {
  return (
    <h3 className="mb-5 text-lg font-normal text-gray-500 ">
      Unfortunately! your submission failed. Please contact our customer service
      team.
    </h3>
  );
}
