import { useEffect, useState } from "react";
import { countState, countCategory } from "../../actions/studensts";
import UNAUTHORIZED from "../UNAUTHORIZED";

export default function Overview() {
  const [size, setSize] = useState(null);
  const [category, setCategory] = useState(null);
  const user = JSON.parse(localStorage.getItem("profile")).value[0].role;

  useEffect(() => {
    const fetchData = async () => {
      const res = await countState();
      const resCategory = await countCategory();
      setSize(res.data.size);
      setCategory(resCategory.data.size);
    };
    fetchData();
  }, []);

  return (
    <div>
      {user !== "admin" ? (
        <UNAUTHORIZED />
      ) : (
        <div>
          <h1 className="text-2xl pt-0 pl-6 pb-5 text-white">Overview</h1>
          <div className="flex flex-wrap justify-around p-3">
            <div className="p-3 border-gray-400 border-2 rounded-lg w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 bg-white">
              <h1 className="text-lg font-semibold">Students: </h1>
              <p className="text-sm font-medium">
                <span className="text-blue-700 text-base">Total: </span>
                <span className="text-red-700">{size?.total}#</span> students
              </p>
            </div>

            <div className="p-3 border-gray-400 border-2 rounded-lg w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 bg-white">
              <h1 className="text-lg font-semibold">Auditing: </h1>

              <p className="text-sm font-medium">
                <span className="text-blue-700 text-base">New: </span>
                <span className="text-red-700">{size?.auditing}#</span> students
              </p>
              <p className="text-sm font-medium">
                <span className="text-blue-700 text-base">Request Edit: </span>
                <span className="text-red-700">{size?.request_edit}#</span>{" "}
                students
              </p>
              <p className="text-sm font-medium">
                <span className="text-blue-700 text-base">
                  Total Auditing:{" "}
                </span>
                <span className="text-red-700">{size?.total_auditing}#</span>{" "}
                students
              </p>
            </div>

            <div className="p-3 border-gray-400 border-2 rounded-lg w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 bg-white">
              <h1 className="text-lg font-semibold">Invoice: </h1>

              <p className="text-sm font-medium">
                <span className="text-blue-700 text-base">New: </span>
                <span className="text-red-700">{size?.invoice}#</span> students
              </p>

              <p className="text-sm font-medium">
                <span className="text-blue-700 text-base">Invoice Wait: </span>
                <span className="text-red-700">{size?.invoice_wait}#</span>{" "}
                students
              </p>
              <p className="text-sm font-medium">
                <span className="text-blue-700 text-base">
                  Another Invoice:{" "}
                </span>
                <span className="text-red-700">{size?.another_invoice}#</span>{" "}
                students
              </p>
              <p className="text-sm font-medium">
                <span className="text-blue-700 text-base">Total Invoice: </span>
                <span className="text-red-700">
                  {size?.total_invoice}#
                </span>{" "}
                students
              </p>
            </div>

            <div className="p-3 border-gray-400 border-2 rounded-lg w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 bg-white">
              <h1 className="text-lg font-semibold">Score Request: </h1>
              <p className="text-sm font-medium">
                <span className="text-blue-700 text-base">
                  Score Validation:{" "}
                </span>
                <span className="text-red-700">{size?.score_validation}#</span>{" "}
                students
              </p>
              <p className="text-sm font-medium">
                <span className="text-blue-700 text-base">Score Request: </span>
                <span className="text-red-700">
                  {size?.score_request}#
                </span>{" "}
                students
              </p>
              <p className="text-sm font-medium">
                <span className="text-blue-700 text-base">Score Pending: </span>
                <span className="text-red-700">
                  {size?.score_pending}#
                </span>{" "}
                students
              </p>
              <p className="text-sm font-medium">
                <span className="text-blue-700 text-base">Total: </span>
                <span className="text-red-700">
                  {size?.total_score_request}#
                </span>{" "}
                students
              </p>
            </div>

            <div className="p-3 border-gray-400 border-2 rounded-lg w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 bg-white">
              <h1 className="text-lg font-semibold">Print: </h1>
              <p className="text-sm font-medium">
                <span className="text-blue-700 text-base">Print: </span>
                <span className="text-red-700">{size?.print}#</span> students
              </p>
              <p className="text-sm font-medium">
                <span className="text-blue-700 text-base">
                  Print Validation:{" "}
                </span>
                <span className="text-red-700">{size?.print_validation}#</span>{" "}
                students
              </p>
              <p className="text-sm font-medium">
                <span className="text-blue-700 text-base">Total: </span>
                <span className="text-red-700">{size?.total_print}#</span>{" "}
                students
              </p>
            </div>

            <div className="p-3 border-gray-400 border-2 rounded-lg w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 bg-white">
              <h1 className="text-lg font-semibold">MOE: </h1>
              <p className="text-sm font-medium">
                <span className="text-blue-700 text-base">
                  MOE Validation:{" "}
                </span>
                <span className="text-red-700">{size?.moe_validation}#</span>{" "}
                students
              </p>
              <p className="text-sm font-medium">
                <span className="text-blue-700 text-base">
                  Sending to MOE:{" "}
                </span>
                <span className="text-red-700">{size?.moe}#</span> students
              </p>
              <p className="text-sm font-medium">
                <span className="text-blue-700 text-base">Moe Pending: </span>
                <span className="text-red-700">{size?.moe_pending}#</span>{" "}
                students
              </p>
              <p className="text-sm font-medium">
                <span className="text-blue-700 text-base">Total: </span>
                <span className="text-red-700">{size?.total_moe}#</span>{" "}
                students
              </p>
            </div>

            <div className="p-3 border-gray-400 border-2 rounded-lg w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 bg-white">
              <h1 className="text-lg font-semibold">Deliver: </h1>
              <p className="text-sm font-medium">
                <span className="text-blue-700 text-base">
                  Deliver Onsite:{" "}
                </span>
                <span className="text-red-700">{size?.deliver_onsite}#</span>{" "}
                students
              </p>
              <p className="text-sm font-medium">
                <span className="text-blue-700 text-base">Aramex: </span>
                <span className="text-red-700">{size?.aramex}#</span> students
              </p>
              <p className="text-sm font-medium">
                <span className="text-blue-700 text-base">Total: </span>
                <span className="text-red-700">
                  {size?.total_deliver}#
                </span>{" "}
                students
              </p>
            </div>

            <div className="p-3 border-gray-400 border-2 rounded-lg w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 bg-white">
              <h1 className="text-lg font-semibold">Final: </h1>
              <p className="text-sm font-medium">
                <span className="text-blue-700 text-base">
                  MOHE Validation:{" "}
                </span>
                <span className="text-red-700">{size?.mohe_validation}#</span>{" "}
                students
              </p>
              <p className="text-sm font-medium">
                <span className="text-blue-700 text-base">
                  Sending to MOHE:{" "}
                </span>
                <span className="text-red-700">{size?.mohe}#</span> students
              </p>
              <p className="text-sm font-medium">
                <span className="text-blue-700 text-base">Done: </span>
                <span className="text-red-700">{size?.done}#</span> students
              </p>
            </div>

            <div className="p-3 border-gray-400 border-2 rounded-lg w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 bg-white">
              <h1 className="text-lg font-semibold">
                Number of Copies(Inside):{" "}
              </h1>
              <p className="text-sm font-medium">
                <span className="text-blue-700 text-base">ACT 1: </span>
                <span className="text-red-700">
                  {category?.act1_copy_number_schools}#
                </span>
                students
              </p>
              <p className="text-sm font-medium">
                <span className="text-blue-700 text-base">ACT 1 MOE: </span>
                <span className="text-red-700">
                  {category?.act1_moe_copy_schools}#
                </span>
                students
              </p>
              <p className="text-sm font-medium">
                <span className="text-blue-700 text-base">ACT 2: </span>
                <span className="text-red-700">
                  {category?.act2_copy_number_schools}#
                </span>
                students
              </p>
              <p className="text-sm font-medium">
                <span className="text-blue-700 text-base">ACT 2 MOE: </span>
                <span className="text-red-700">
                  {category?.act2_moe_copy_schools}#
                </span>
                students
              </p>
            </div>

            <div className="p-3 border-gray-400 border-2 rounded-lg w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 bg-white">
              <h1 className="text-lg font-semibold">
                Number of Copies(Individual):{" "}
              </h1>
              <p className="text-sm font-medium">
                <span className="text-blue-700 text-base">ACT 1: </span>
                <span className="text-red-700">
                  {category?.act1_copy_number_individual}#
                </span>
                students
              </p>
              <p className="text-sm font-medium">
                <span className="text-blue-700 text-base">ACT 1 MOE: </span>
                <span className="text-red-700">
                  {category?.act1_moe_copy_individual}#
                </span>
                students
              </p>
              <p className="text-sm font-medium">
                <span className="text-blue-700 text-base">ACT 2: </span>
                <span className="text-red-700">
                  {category?.act2_copy_number_individual}#
                </span>
                students
              </p>
              <p className="text-sm font-medium">
                <span className="text-blue-700 text-base">ACT 2 MOE: </span>
                <span className="text-red-700">
                  {category?.act2_moe_copy_individual}#
                </span>
                students
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
