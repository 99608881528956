import { useLocation, useNavigate } from "react-router-dom";
import InfoRow from "../../components/InfoRow";
import UNAUTHORIZED from "../UNAUTHORIZED";
import ActionButtonsPrint from "./ActionButtonsPrint";
import ActionButtonsValid from "./ActionButtonsValid";
import jsPDF from "jspdf";
import { Button } from "react-bootstrap";
import logo1 from "./logo1.png";
import logo from "./logo.png";
import html2canvas from "html2canvas";
export default function StudentPrint() {
  const user = JSON.parse(localStorage.getItem("profile")).value[0].role;
  const location = useLocation();
  const navigate = useNavigate();
  const studentType = useLocation().pathname.split("/")[3];
  const element = location.state;
  const generatePDF = () => {
    const doc = new jsPDF();
    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleDateString();

    doc.setFontSize(8);
    doc.setFont("helvetica");
    doc.text(`Generated on: ${formattedDate}`, 93, 275);
    doc.text("To: The Arab Republic of Egypt", 15, 33);
    doc.text("Ministry of Education", 15, 37);
    doc.text("AL Falaki St. 12", 15, 41);
    doc.text("Cairo", 15, 45);
    doc.text("Egypt", 15, 49);
    const imgWidth = 50;
    const imgHeight = 15;
    doc.addImage(logo1, "PNG", 140, 30, imgWidth, imgHeight);
    doc.setFontSize(10);
    doc.text(`Dear Sir/Madam`, 15, 95);
    doc.setFontSize(9);
    doc.text(
      `We would like to confirm that the attached ACT and ACT International Subject Test score reports for the student named :`,
      15,
      106
    );
    doc.setFontSize(11);
    doc.setFont("helvetica", "bold");
    const fullName = element.full_name.toUpperCase();
    doc.text(`${fullName}`, 15, 115);
    doc.text(`NATIONAL ID: ${element.id}`, 15, 124);
    doc.setFont("helvetica", "normal");
    doc.setFontSize(10);
    let examName = element.exam_name
      .replace("ACT1 ", "")
      .replace("Certificate", "")
      .replace("Score", "");
    doc.text(`have been verified and confirmed as correct scores.`, 15, 132);
    if (element.subject1 !== null && element.act2_score1 !== null) {
      doc.text(
        `the score on the international subject test (${element.subject1}) is ${element.act2_score1}/36`,
        15,
        140
      );
    }
    if (element.subject2 !== null && element.act2_score2 !== null) {
      doc.text(
        `the score on the international subject test (${element.subject2}) is ${element.act2_score2}/36`,
        15,
        145
      );
    }
    if (element.subject3 !== null && element.act2_score3 !== null) {
      doc.text(
        `the score on the international subject test (${element.subject3}) is ${element.act2_score3}/36`,
        15,
        150
      );
    }
    doc.text(
      `And the composite of the ACT ${examName}Score is ${element.act1_score1}/36`,
      15,
      155
    );
    doc.text(`Yours truly,`, 15, 170);
    doc.setFont("helvetica", "bold");
    doc.setFontSize(14);

    doc.text(`DR.Hussam Ezz Eldin`, 15, 180);
    doc.addImage(logo, "PNG", 15, 185, imgWidth, imgHeight);
    doc.setFont("helvetica", "normal");
    doc.setFontSize(10);
    doc.text(`Chief Executive Officer`, 15, 210);
    doc.text(`The ACT MENA International Resource Partner`, 15, 218);
    doc.text(`https://www.americanacademyeg.org`, 15, 224);
    doc.text(`To verify the certificate, you can scan the QR code`, 15, 230);
    doc.setTextColor(0, 0, 255);
    doc.text(`Scan QR Code`, 150, 225);
    doc.save(`ACT-1+2-${element.id}.pdf`);
  };

  const generate_Arabic_PDF = () => {
    const doc = new jsPDF();
    let arabicText = `
      <div>
        <h1>الطالب عائد من الخارج وتم اختباره بدولة <strong>${element.center_test_country}</strong> </h1>
      `;
    if (element.subject1 && element.act2_score1) {
      arabicText += `
      <h1>وحاصل على <strong>${element.act2_score1}</strong>/36 في مادة <strong>${element.subject1}</strong> </h1>
      `;
    }
    if (element.subject2 && element.act2_score2) {
      arabicText += `
      <h1>وحاصل على <strong>${element.act2_score2}</strong>/36 في مادة <strong>${element.subject2}</strong> </h1>
      `;
    }
    if (element.subject3 && element.act2_score3) {
      arabicText += `
      <h1>وحاصل على <strong>${element.act2_score3}</strong>/36 في مادة <strong>${element.subject3}</strong> </h1>
      `;
    }
    arabicText += `
      <p>وذلك في ضوء مراجعة واعتماد الشركة المسؤولة عن اختبارات ACT داخل جمهورية مصر العربية،</p>
      <p>المفوضة من الشركة الأم بالولايات المتحدة الأمريكية، وعلى مسؤوليتها، وجميع المستندات العائدة بها من الخارج تتم بمعرفة مكتب التنسيق بوزارة التعليم العالي.</p>
        <p style="margin-top: 5%; font-size:30px;">اللجنة</p>
        <p style="margin-top: 5%; font-size:30px;">مدير الاداره</p>
        <div style="display: flex; flex-direction: column; align-items: center; justify-content: center; margin-top: 20%; margin-right: 20%; font-size: 30px;">
      <p><strong>يعتمد</strong></p>
      <p><strong>مساعد الوزير لشئون التعليم الخاص والدولي</strong></p>
      <p><strong>والمشرف علي الاداره العامه للتعليم الخاص والدولي</strong></p>
    </div>
    </div>
    `;

    const arabicElement = document.createElement("div");
    arabicElement.style.fontSize = "36px";
    arabicElement.style.fontFamily = "Times New Roman";
    arabicElement.style.direction = "rtl";
    arabicElement.style.padding = "20px";
    arabicElement.style.margin = "20px";
    arabicElement.innerHTML = arabicText;
    document.body.appendChild(arabicElement);

    html2canvas(arabicElement).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const imgProps = doc.getImageProperties(imgData);
      const pdfWidth = doc.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      doc.addImage(imgData, "PNG", 0, 40, 200, pdfHeight);
      // Save the PDF
      doc.save(`MOE-Send-${element.id}.pdf`);
      // Clean up
      document.body.removeChild(arabicElement);
    });
  };
  return (
    <div>
      {user !== "user" && user !== "admin" ? (
        <UNAUTHORIZED />
      ) : (
        <div className="flex justify-center">
          <div className="lg:w-3/5 md:w-3/4 w-full border shadow flex flex-col p-3 rounded-lg bg-gray-50">
            <div className="flex items-center justify-between">
              <span className="text-xl">Student Information</span>
              <span
                className="cursor-pointer rounded-full w-9 h-9 bg-red-400 text-white flex items-center justify-center active:scale-90 hover:bg-red-500"
                onClick={() => navigate(-1)}
              >
                X
              </span>
            </div>
            <div className="flex flex-col m-3">
              <InfoRow data={element.id} label={"ID"} about="id" />
              <InfoRow
                data={element.full_name}
                label={"Full Name"}
                about="full_name"
              />
              <InfoRow data={element.email} label={"Email"} about="email" />
              <InfoRow
                data={element.address}
                label={"Address"}
                about="address"
              />
              <InfoRow
                data={element.postal_code}
                label={"Postal Code"}
                about="postal_code"
              />
              <InfoRow
                data={element.date_of_birth}
                label={"Data of Birth"}
                about="data_of_birth"
              />
              <InfoRow data={element.gender} label={"Gender"} about="gender" />
              <InfoRow data={element.phone} label={"Phone"} about="phone" />
              <InfoRow
                data={element.school_name}
                label={"School Name"}
                about="school_name"
              />
              <InfoRow
                data={element.test_center_name}
                label={"Test Center Name"}
                about="test_center_name"
              />

              <div className="p-2 border rounded mt-1 bg-blue-50">
                <InfoRow
                  data={element.act1_id}
                  label={"ACT 1 ID"}
                  about="act1_id"
                />
                <InfoRow
                  data={element.exam_name}
                  label={"Exam Name"}
                  about="exam_name"
                />
                <InfoRow
                  data={element.act1_score1}
                  label={"Score 1"}
                  about="act1_score1"
                />
                <InfoRow
                  data={element.act1_score2}
                  label={"Score 2"}
                  about="act1_score2"
                />
                <InfoRow
                  data={element.act1_score3}
                  label={"Score 3"}
                  about="act1_score3"
                />
                <InfoRow
                  data={element.act1_try_date1}
                  label={"Trial Date 1"}
                  about="act1_try_date1"
                />
                <InfoRow
                  data={element.act1_try_date2}
                  label={"Trial Date 2"}
                  about="act1_try_date2"
                />
                <InfoRow
                  data={element.act1_try_date3}
                  label={"Trial Date 3"}
                  about="act1_try_date3"
                />
                <InfoRow
                  data={element.act1_try_date4}
                  label={"Trial Date 4"}
                  about="act1_try_date4"
                />
                <InfoRow
                  data={element.act1_try_date5}
                  label={"Trial Date 5"}
                  about="act1_try_date5"
                />
                <InfoRow
                  data={element.act1_copy_number}
                  label={"Copies Number"}
                  about="act1_copy_number"
                />
                <InfoRow
                  data={element.act1_moe_copy}
                  label={"MOE Copies Number"}
                  about="act1_moe_copy"
                />
              </div>
              {element.act2 && (
                <div className="p-2 border rounded mt-1 bg-green-50">
                  <InfoRow
                    data={element.act2_id}
                    label={"ACT 2 ID"}
                    about="act2_id"
                  />
                  <InfoRow
                    data={element.act2_score1}
                    label={"Score 1"}
                    about="act2_score1"
                  />
                  <InfoRow
                    data={element.act2_score2}
                    label={"Score 2"}
                    about="act2_score2"
                  />
                  <InfoRow
                    data={element.act2_score3}
                    label={"Score 3"}
                    about="act2_score3"
                  />
                  <InfoRow
                    data={element.act2_try_date1}
                    label={"Trial Date 1"}
                    about="act2_try_date1"
                  />
                  <InfoRow
                    data={element.act2_try_date2}
                    label={"Trial Date 2"}
                    about="act2_try_date2"
                  />
                  <InfoRow
                    data={element.act2_try_date3}
                    label={"Trial Date 3"}
                    about="act2_try_date3"
                  />
                  <InfoRow
                    data={element.subject1}
                    label={"Subject 1"}
                    about="subject1"
                  />
                  <InfoRow
                    data={element.subject2}
                    label={"Subject 2"}
                    about="subject2"
                  />
                  <InfoRow
                    data={element.subject3}
                    label={"Subject 3"}
                    about="subject3"
                  />
                  <InfoRow
                    data={element.act2_copy_number}
                    label={"Copies Number"}
                    about="act2_copy_number"
                  />
                  <InfoRow
                    data={element.act2_moe_copy}
                    label={"MOE Copies Number"}
                    about="act2_moe_copy"
                  />
                </div>
              )}
            </div>
            <div
  style={{
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center" 
  }}
>
  {studentType === "new" ? (
    <>
      <div className="flex space-x-4 mb-4">
        <button
          onClick={generatePDF}
          className="px-4 py-2 border border-blue-600 text-black-600 bg-transparent hover:bg-black hover:text-white rounded transition duration-300"
        >
          Download Combined PDF
        </button>

        <button
          onClick={generate_Arabic_PDF}
          className="px-4 py-2 border border-gray-600 text-white bg-blue-500 hover:bg-gray-600 hover:text-black rounded transition duration-300"
        >
          Generate Moe Sheet
        </button>
      </div>
      <ActionButtonsPrint />
    </>
  ) : (
    <>
      {studentType === "print_validation" ? (
        <ActionButtonsValid />
      ) : null}
    </>
  )}
</div>

          </div>
        </div>
      )}
    </div>
  );
}
