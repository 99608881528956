import React from "react";

export default function Input({
  type,
  placeholder,
  name,
  required = true,
  onChange,
  value,
  float = false,
  disabled = false,
  pattern,
  title,
}) {
  return (
    <div className="m-2 flex items-center justify-center">
      <label htmlFor={name} className="flex-auto">
        {required && <span className="text-red-600">*</span>}
        {placeholder}:
      </label>
      <input
        autoComplete="off"
        type={type}
        placeholder={placeholder}
        className="flex-initial p-2 ml-1 border rounded-xl focus:outline-blue-700"
        required={required}
        onChange={onChange}
        name={name}
        disabled={disabled}
        value={value}
        checked={type === "checkbox" ? value : null}
        accept={type === "file" ? "*" : null}
        step={float ? "0.01" : "1"}
        onFocus={(e) => e.target.select()}
        pattern={pattern}
        title={title}
      />
    </div>
  );
}
