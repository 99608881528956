import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import Table from "./pages/Table/index";
import GlobalActions from "./pages/Panel/GlobalActions";
import Overview from "./pages/Panel/Overview";
import Form from "./pages/Form/Form";
import Login from "./pages/Login";
import Redirect from "./pages/Redirect/Redirect";
import Sidebar from "./pages/SideBar/Sidebar";
import Main from "./pages/Main";
import Invoice from "./pages/Invoice/Invoice";
import SendMailInv from "./pages/Invoice/SendMailInv";
import StudentInv from "./pages/Invoice/StudentInv";
import Auditing from "./pages/Auditing/Auditing";
import StudentAud from "./pages/Auditing/StudentAud";
import Edit from "./pages/Edit";
import AddUser from "./pages/AddUser/AddUser";
import SendMailAud from "./pages/Auditing/SendMailAud";
import ScoreHandler from "./pages/ScoreHandler/ScoreHandler";
import StudentScore from "./pages/ScoreHandler/StudentScore";
import Print from "./pages/Print/Print";
import StudentPrint from "./pages/Print/StudentPrint";
import Moe from "./pages/Moe/Moe";
import StudentMoe from "./pages/Moe/StudentMoe";
import Deliver from "./pages/Deliver/Deliver";
import StudentDeliver from "./pages/Deliver/StudentDeliver";
import Done from "./pages/Done/Done";
import StudentDone from "./pages/Done/StudentDone";
import ScoreComment from "./pages/ScoreHandler/ScoreComment";
import MoeComment from "./pages/Moe/MoeComment";
import MOHE from "./pages/MOHE/MOHE";
import StudentMOHE from "./pages/MOHE/StudentMOHE";

function App() {
  return (
    <Routes>
      <Route path="/form/*" element={<Form />} />
      <Route path="/redirect" element={<Redirect />} />
      <Route path="/login" element={<Login />} />
      <Route path="/main" element={<Sidebar />}>
        <Route path="" element={<Main />} />
        {/* start admin routes */}
        <Route path="overview" element={<Overview />} />
        <Route path="table" element={<Table />} />
        <Route path="actions" element={<GlobalActions />} />
        <Route path="add_user" element={<AddUser />} />
        {/* end admin routes */}
        {/* start auditing routes */}
        <Route path="auditing/new" element={<Auditing />} />
        <Route path="auditing/new/:id" element={<StudentAud />} />
        <Route path="auditing/new/edit" element={<Edit />} />
        <Route path="auditing/request_edit" element={<Auditing />} />
        <Route path="auditing/request_edit/:id" element={<StudentAud />} />
        <Route path="auditing/request_edit/edit" element={<Edit />} />
        <Route path="auditing/request_edit/mail" element={<SendMailAud />} />
        {/* end auditing routes */}
        {/* start invoice routes */}
        <Route path="invoice/new" element={<Invoice />} />
        <Route path="invoice/another_invoice" element={<Invoice />} />
        <Route path="invoice/wait" element={<Invoice />} />
        <Route path="invoice/new/:id" element={<StudentInv />} />
        <Route path="invoice/another_invoice/:id" element={<StudentInv />} />
        <Route path="invoice/wait/:id" element={<StudentInv />} />
        <Route path="invoice/new/mail" element={<SendMailInv />} />
        {/* end invoice routes */}
        {/* start score request routes */}
        <Route path="score/score_validation" element={<ScoreHandler />} />
        <Route path="score/new" element={<ScoreHandler />} />
        <Route path="score/score_pending" element={<ScoreHandler />} />
        <Route path="score/score_validation/:id" element={<StudentScore />} />
        <Route path="score/new/:id" element={<StudentScore />} />
        <Route path="score/score_pending/:id" element={<StudentScore />} />
        <Route path="score/score_pending/comment" element={<ScoreComment />} />
        {/* end score request routes */}
        {/* start print routes */}
        <Route path="print/new" element={<Print />} />
        <Route path="print/print_validation" element={<Print />} />
        <Route path="print/print_validation/:id" element={<StudentPrint />} />
        <Route path="print/new/:id" element={<StudentPrint />} />
        {/* end print routes */}
        {/* start moe routes */}
        <Route path="moe/moe_validation" element={<Moe />} />
        <Route path="moe/new" element={<Moe />} />
        <Route path="moe/moe_pending" element={<Moe />} />
        <Route path="moe/moe_validation/:id" element={<StudentMoe />} />
        <Route path="moe/new/:id" element={<StudentMoe />} />
        <Route path="moe/moe_pending/:id" element={<StudentMoe />} />
        <Route path="moe/moe_pending/comment" element={<MoeComment />} />
        {/* end moe routes */}
        {/* start deliver routes */}
        <Route path="deliver/deliver_onsite" element={<Deliver />} />
        <Route path="deliver/aramex" element={<Deliver />} />
        <Route path="deliver/deliver_onsite/:id" element={<StudentDeliver />} />
        <Route path="deliver/aramex/:id" element={<StudentDeliver />} />
        {/* end deliver routes */}
        {/* start Final routes */}
        <Route path="mohe/mohe_validation" element={<MOHE />} />
        <Route path="mohe/new" element={<MOHE />} />
        <Route path="mohe/mohe_validation/:id" element={<StudentMOHE />} />
        <Route path="mohe/new/:id" element={<StudentMOHE />} />
        <Route path="done" element={<Done />} />
        <Route path="done/:id" element={<StudentDone />} />
        {/* end done routes */}
      </Route>
      <Route path="/" element={<Navigate to="/login" />} />
      <Route path="*" element={<Navigate to="/form" />} />
    </Routes>
  );
}

export default App;
