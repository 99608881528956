function TermsAndConditions() {
  return (
    <div className="h-44 overflow-scroll">
      <h6 className="text-center text-lg text-red-600">
        American Academy for Training <br />
        Examinee Authorization to Collect, Process and Disclose <br />
        Personally Identifiable Information
      </h6>
      <hr />
      <p className="max-w-2xl mt-2">
        American Academy for Training (“AATC”) provides test score reporting
        verification services to examinees in Egypt. AATC is authorized by ACT,
        Inc. to provide validation services to ACT and ACT International Subject
        Test (“AIST”) examinees in Egypt for the purpose of providing the
        validated ACT and AIST scores to the Egyptian Ministry of Education and
        other Egyptian government agencies. By completing and signing this
        Authorization Form, You authorize AATC to collect, process, and disclose
        your personally identifiable information to ACT, Inc. in the United
        States, and to the [Egyptian government], as set forth in the terms
        below:
      </p>
      <p className="mt-2 font-semibold bg-amber-100">
        Personally Identifiable Information Data to be Collected, Processed, and
        Disclosed :
      </p>
      <p className="max-w-2xl">
        You, the Examinee identified below, authorize AATC to provide ACT, Inc.
        and the Egyptian Government with the following personally identifiable
        information: <br />
        <strong>(a)</strong> ACT and/or AIST test score data for all ACT and
        AIST tests Examinee has taken in Examinee’s lifetime; <br />
        <strong>(b)</strong> National Identification Number; <br />
        <strong>(c)</strong>All associated Examinee personally identifiable
        information which AATC and/or ACT, Inc. maintain regarding you
        (including but not limited to, email, date of birth, address, parents
        name, phone number, etc). <br />
      </p>
      <p className="mt-2 font-semibold bg-amber-100">Terms and Conditions</p>
      <p className="max-w-2xl">
        1. <u>Disclosure:</u> You authorize AATC to provide your Personally
        Identifiable Information (listed above) to ACT, Inc. in the United
        States for purposes of verifying your score on the ACT and/or AIST test.
        ACT may also associate your personally identifiable information with
        Test Security Information and other data which ACT maintains related to
        your taking of the ACT and/or AIST tests (including, but not limited to,
        an invalidated ACT or AIST score or existence of a test security
        investigation regarding the validity of your score). You also authorize
        AATC and/or ACT, Inc. to provide your Personally Identifiable
        Information to the Egyptian Ministry of Education or other government
        agencies in Egypt.
        <br />
        2. <u>Representations and Warranties.</u> You represent and warrant that
        you are the person identified below and have provided true and correct
        information to AATC. You represent and warrant that you comply with all
        applicable laws, regulations and rules.
      </p>
      <p className="max-w-2xl mt-2">
        <strong className="text-red-500">Signature required—</strong>
        Complete the identifying information below, then read and sign this
        Authorization if you are in agreement. By signing and submitting this
        Authorization Form, you agree to the terms and conditions set forth
        above, that you are the person identified below and all information you
        have provided is accurate and correct.
      </p>
      <hr />
      <p className="max-w-2xl mt-4">
        <strong>
          <u>Test Security Investigations.</u>
        </strong>{" "}
        As part of its efforts to protect the fairness of the ACT exam and the
        integrity of ACT scores, ACT may conduct an investigation into the
        security of its test materials and the testing process (“Test Security
        Investigation”), and you agree to cooperate with any Test Security
        Investigation. ACT reserves the right to hold and not report scores
        pending the outcome of a Test Security Investigation. Test Security
        Investigations may produce evidence that causes ACT to initiate one or
        more Individual Score Reviews.
        <br /> <br />
        In addition to taking action regarding examinee scores, ACT may, in its
        sole discretion, decide as part of a Test Security Investigation that
        additional steps are appropriate, such as when prohibited behavior is or
        might be ongoing or rises to the level of an infringement of ACT’s
        intellectual property rights or a criminal act (e.g., coordinated group
        efforts to steal and disseminate ACT’s secure test content; activities
        involving someone else taking the test for another person or altering
        test responses; theft of, disclosure of, or access to test content or
        responses to secure test questions; or other serious or potentially
        ongoing misconduct). Those additional steps may include, but are not
        limited to, the following:
        <br /> <br />
        a. <u>Prohibiting You from Taking the ACT:</u> ACT may prohibit you from
        taking the ACT test or using ACT scoring, even if you have already
        registered, if ACT has reason to believe (1) you engaged in prohibited
        behavior in connection with a prior or future administration of the ACT
        test, (2) you intend to engage in prohibited behavior in the future, (3)
        you have provided false or misleading information to ACT or to others
        relating to your taking the ACT test, or (4) you are working for a test
        preparation provider. This prohibition may be temporary or permanent.
        All of these activities or all suspicious activities may lead also to
        cancel your scores, even if your score have been authenticated by the
        Americana Academy or the Ministry of Education.
        <br /> <br />
        b. <u>Disclosure of Investigative Information:</u> ACT may disclose
        details of a Test Security Investigation to anyone who may be able to
        assist ACT in the investigation or who may have an interest in their own
        independent investigation, such as law enforcement, state departments of
        education, local school officials, and official score recipients. Such
        disclosures help protect the integrity and fairness of the examination
        process and benefit examinees who properly obtain their ACT scores, as
        well as official score recipients. You authorize and consent to these
        disclosures.
        <br /> <br />
        By signing this agreement, you agree to cancel your score with or
        without reason and keep the American Academy and the ACT protected from
        any future legal action. You also agree not to take any legal action
        against the ACT or the American Academy. I do that the first name, last
        name, and e-mail address that belongs to the test taker are correct.
      </p>
      <p className="max-w-2xl mt-2">
        <strong className="text-red-500">Signature required—</strong>
        Complete the identifying information below, then read and sign this
        Authorization if you are in agreement. By signing and submitting this
        Authorization Form, you agree to the terms and conditions set forth
        above, that you are the person identified below and all information you
        have provided is accurate and correct.
      </p>
    </div>
  );
}

export default TermsAndConditions;
