import Input from "../../components/Input";

import Search from "./Search";

export default function Header({
  selected,
  setShowJumpToPop,
  setShowPop,
  setShowChangeCategory,
  onChangeEdit,
  setSearch,
  setPage,
  getSelectState,
  exportState,
}) {
  const disabled = selected ? false : true;
  const { username } = JSON.parse(localStorage.getItem("profile")).value[0];

  return (
    <header className="p-4 flex bg-gray-200 justify-center border rounded-lg">
      <div>
        <div className="flex justify-around p-2">
          <Search setSearch={setSearch} setPage={setPage} />
          <div>
            <Input
              type="checkbox"
              name="edit"
              placeholder="Request Edit"
              required={false}
              onChange={onChangeEdit}
            />
          </div>
        </div>
        <div className="flex justify-around">
          <div className="p-1">
            <button
              type="button"
              className="text-white bg-red-700 hover:bg-red-800 
                           focus:ring-4 focus:ring-red-400 font-medium rounded-lg text-sm px-5 py-2.5 text-center disabled:cursor-not-allowed disabled:bg-red-300"
              disabled={disabled}
              onClick={() => setShowPop(true)}
            >
              Delete
            </button>
          </div>
          <div className="p-1">
            <button
              type="button"
              className="text-white bg-blue-700 hover:bg-blue-800 
                          focus:ring-4 focus:ring-blue-400 font-medium rounded-lg text-sm px-5 py-2.5 text-center disabled:cursor-not-allowed disabled:bg-blue-300"
              disabled={disabled}
              onClick={() => setShowJumpToPop(true)}
            >
              Jump To State
            </button>
          </div>
          <div className="p-1">
            <button
              type="button"
              className="text-white bg-green-700 hover:bg-green-800 
                          focus:ring-4 focus:ring-green-400 font-medium rounded-lg text-sm px-5 py-2.5 text-center disabled:cursor-not-allowed disabled:bg-green-400"
              disabled={disabled}
              onClick={() => setShowChangeCategory(true)}
            >
              Change Category
            </button>
          </div>
        </div>
        {(username === "asmaa" ||
          username === "nour" ||
          username === "abdelrhman") && (
          <div className="flex justify-around p-2">
            <div>
              <div>
                <label className="fs-4 fw-normal" htmlFor="states">
                  Select a State to Export:{" "}
                </label>
              </div>
              <select
                onChange={(e) => getSelectState(e.target.value)}
                className="w-40 p-1"
                id="states"
              >
                <option value="all">all</option>
                <option value="auditing">auditing</option>
                <option value="invoice">invoice</option>
                <option value="invoice_wait">invoice_wait</option>
                <option value="another_invoice">another_invoice</option>
                <option value="request_edit">request_edit</option>
                <option value="score_validation">score_validation</option>
                <option value="score_request">score_request</option>
                <option value="score_pending">score_pending</option>
                <option value="print">print</option>
                <option value="print_validation">print_validation</option>
                <option value="moe_validation">moe_validation</option>
                <option value="moe">moe</option>
                <option value="moe_pending">moe_pending</option>
                <option value="deliver_onsite">deliver_onsite</option>
                <option value="aramex">aramex</option>
                <option value="mohe_validation">mohe_validation</option>
                <option value="mohe">mohe</option>
                <option value="done">done</option>
              </select>
            </div>
            <button
              type="button"
              className="text-white bg-green-900 hover:bg-green-800 p-2 
                        focus:ring-4 focus:ring-green-400 font-medium rounded-lg text-sm text-center cursor-pointer"
              onClick={exportState}
            >
              Export
            </button>
          </div>
        )}
      </div>
    </header>
  );
}
