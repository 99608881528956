import { useLocation, useNavigate } from "react-router-dom";
import Button from "../../components/Button";
import { scoreComment } from "../../actions/studensts";
import { useState } from "react";
import AlertDenger from "../../components/AlertDenger";
import AlertSuccess from "../../components/AlertSuccess";
import Loading from "../../components/Loading";

export default function ScoreComment() {
  const navigate = useNavigate();
  const [msg, setMsg] = useState("");
  const id = useLocation().state;
  const [text, setText] = useState("");
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showDengerAlert, setShowDengerAlert] = useState(false);
  const [loading, setLoading] = useState(false);

  if (showSuccessAlert || showDengerAlert) {
    setTimeout(function () {
      setShowSuccessAlert(false);
      setShowDengerAlert(false);
      setLoading(false);
      navigate(`/main/score/score_pending`);
    }, 2000);
  }

  const handleSend = async () => {
    if (msg.length === 0) {
      setShowDengerAlert(true);
      setText("Can't save an Comment");
      return;
    }
    setLoading(true);
    const res = await scoreComment(
      id,
      msg,
      JSON.parse(localStorage.getItem("profile"))?.value[0].username,
    );
    if (res.status === 200) {
      setShowSuccessAlert(true);
      setText(res.data.message);
    } else {
      setShowDengerAlert(true);
      setText(res.data.ERROR);
    }
  };

  return (
    <div className="flex justify-center">
      <div
        className="lg:w-3/5 md:w-3/4 w-full min-h-max border shadow
    flex flex-col bg-white rounded-lg p-12"
      >
        {showSuccessAlert && <AlertSuccess text={text} />}
        {showDengerAlert && <AlertDenger text={text} />}
        <div className="flex items-center justify-between">
          <h1 className="text-xl">Write a Comment</h1>
          <span
            className="cursor-pointer rounded-lg w-9 h-9 border 
        flex items-center justify-center active:scale-90 hover:bg-gray-200"
            onClick={() => navigate(-1)}
          >
            X
          </span>
        </div>

        <textarea
          name="issue"
          rows="6"
          placeholder="Write a Comment here..."
          className="bg-gray-100 rounded-lg resize-none mt-4 p-1
         outline-blue-700"
          value={msg}
          onChange={(e) => setMsg(e.target.value)}
        ></textarea>

        <Button
          text={!loading ? "Save" : <Loading />}
          color="bg-blue-500 disabled:bg-gray-300 mt-4"
          disabled={loading}
          onClick={handleSend}
        />
      </div>
    </div>
  );
}
