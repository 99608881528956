import { useLocation, useNavigate } from "react-router-dom";
import InfoRow from "../../components/InfoRow";
import UNAUTHORIZED from "../UNAUTHORIZED";
import ActionButtonsNew from "./ActionButtonsNew";
import ActionButtonsPending from "./ActionButtonsPending";
import ActionButtonsValid from "./ActionButtonsValid";

export default function StudentScore() {
  const user = JSON.parse(localStorage.getItem("profile")).value[0].role;
  const studentType = useLocation().pathname.split("/")[3];
  const location = useLocation();
  const navigate = useNavigate();

  const element = location.state;

  return (
    <div>
      {user !== "user" && user !== "admin" ? (
        <UNAUTHORIZED />
      ) : (
        <div className="flex justify-center">
          <div
            className="lg:w-3/5 md:w-3/4 w-full border shadow
    flex flex-col p-3 rounded-lg bg-gray-50"
          >
            <div className="flex items-center justify-between">
              <span className="text-xl">Student Information</span>
              <span
                className="cursor-pointer rounded-full w-9 h-9 bg-red-400 text-white 
        flex items-center justify-center active:scale-90 hover:bg-red-500"
                onClick={() => navigate(-1)}
              >
                X
              </span>
            </div>
            <div className="flex flex-col m-3">
              <InfoRow data={element.id} label={"ID"} about="id" />
              <InfoRow
                data={element.full_name}
                label={"Full Name"}
                about="full_name"
              />
              <InfoRow data={element.email} label={"Email"} about="email" />
              <InfoRow
                data={element.address}
                label={"Address"}
                about="address"
              />
              <InfoRow
                data={element.postal_code}
                label={"Postal Code"}
                about="postal_code"
              />
              <InfoRow
                data={element.date_of_birth}
                label={"Data of Birth"}
                about="data_of_birth"
              />
              <InfoRow data={element.gender} label={"Gender"} about="gender" />
              <InfoRow data={element.phone} label={"Phone"} about="phone" />
              <InfoRow
                data={element.school_name}
                label={"School Name"}
                about="school_name"
              />
              <InfoRow
                data={element.test_center_name}
                label={"Test Center Name"}
                about="test_center_name"
              />

              <div className="p-2 border rounded mt-1 bg-blue-50">
                <InfoRow
                  data={element.act1_id}
                  label={"ACT 1 ID"}
                  about="act1_id"
                />
                <InfoRow
                  data={element.exam_name}
                  label={"Exam Name"}
                  about="exam_name"
                />
                <InfoRow
                  data={element.act1_score1}
                  label={"Score 1"}
                  about="act1_score1"
                />
                <InfoRow
                  data={element.act1_score2}
                  label={"Score 2"}
                  about="act1_score2"
                />
                <InfoRow
                  data={element.act1_score3}
                  label={"Score 3"}
                  about="act1_score3"
                />
                <InfoRow
                  data={element.act1_try_date1}
                  label={"Trial Date 1"}
                  about="act1_try_date1"
                />
                <InfoRow
                  data={element.act1_try_date2}
                  label={"Trial Date 2"}
                  about="act1_try_date2"
                />
                <InfoRow
                  data={element.act1_try_date3}
                  label={"Trial Date 3"}
                  about="act1_try_date3"
                />
                <InfoRow
                  data={element.act1_try_date4}
                  label={"Trial Date 4"}
                  about="act1_try_date4"
                />
                <InfoRow
                  data={element.act1_try_date5}
                  label={"Trial Date 5"}
                  about="act1_try_date5"
                />
                <InfoRow
                  data={element.act1_copy_number}
                  label={"Copies Number"}
                  about="act1_copy_number"
                />
                <InfoRow
                  data={element.act1_moe_copy}
                  label={"MOE Copies Number"}
                  about="act1_moe_copy"
                />
              </div>
              {element.act2 && (
                <div className="p-2 border rounded mt-1 bg-green-50">
                  <InfoRow
                    data={element.act2_id}
                    label={"ACT 2 ID"}
                    about="act2_id"
                  />
                  <InfoRow
                    data={element.act2_score1}
                    label={"Score 1"}
                    about="act2_score1"
                  />
                  <InfoRow
                    data={element.act2_score2}
                    label={"Score 2"}
                    about="act2_score2"
                  />
                  <InfoRow
                    data={element.act2_score3}
                    label={"Score 3"}
                    about="act2_score3"
                  />
                  <InfoRow
                    data={element.act2_try_date1}
                    label={"Trial Date 1"}
                    about="act2_try_date1"
                  />
                  <InfoRow
                    data={element.act2_try_date2}
                    label={"Trial Date 2"}
                    about="act2_try_date2"
                  />
                  <InfoRow
                    data={element.act2_try_date3}
                    label={"Trial Date 3"}
                    about="act2_try_date3"
                  />
                  <InfoRow
                    data={element.subject1}
                    label={"Subject 1"}
                    about="subject1"
                  />
                  <InfoRow
                    data={element.subject2}
                    label={"Subject 2"}
                    about="subject2"
                  />
                  <InfoRow
                    data={element.subject3}
                    label={"Subject 3"}
                    about="subject3"
                  />
                  <InfoRow
                    data={element.act2_copy_number}
                    label={"Copies Number"}
                    about="act2_copy_number"
                  />
                  <InfoRow
                    data={element.act2_moe_copy}
                    label={"MOE Copies Number"}
                    about="act2_moe_copy"
                  />
                </div>
              )}
            </div>
            <div>
              <div className="m-2">
                {studentType === "new" ? (
                  <ActionButtonsNew />
                ) : (
                  <>
                    {studentType === "score_pending" ? (
                      <ActionButtonsPending />
                    ) : (
                      <>
                        {studentType === "score_validation" ? (
                          <ActionButtonsValid />
                        ) : null}
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
