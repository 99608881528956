import Input from "../../components/Input";
import { createAnotherInvoice, GetStInvNum } from "../../actions/studensts";
import AlertDenger from "../../components/AlertDenger";
import AlertSuccess from "../../components/AlertSuccess";
import Loading from "../../components/Loading";
import { useEffect, useState } from "react";

const exam_name = [
  "ACT1 One Trial Certificate",
  "ACT1 Super Score Certificate",
  "ACT1 Matching Certificate",
];

const initialValue = {
  exam_name: exam_name[0],
  month: "",
  year: "",
  act1: false,
  act1_copy_number: 0,
  act1Crt: 0,
  act2: false,
  act2Crt: 0,
  act2_month: "",
  act2_year: "",
  subject: "",
  act2_copy_number: 0,
  admin_fees: 0,
  shipping: 0,
  moe_auth: false,
  moe1_copy_number: 0,
  moe1_number: 0,
  moe2_copy_number: 0,
  moe2_number: 0,
  act2_id: "",
  act1_try: "",
  act2_try: "",
  discount: 0.0,
  invoice_type: "",
};

export default function AnotherInvoice({ id }) {
  const url = "https://americanacademyeg.net/api/v2";
  // const url = "https://english-kingdom.com/api/v2";
  // const url = "http://localhost:5000/api/v2";

  const user = JSON.parse(localStorage.getItem("profile")).value[0].username;
  const [invoiceData, setInvoiceData] = useState({ ...initialValue, id });
  const [invoiceNum, setInvoiceNum] = useState(0);
  const [text, setText] = useState("");
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showDengerAlert, setShowDengerAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const [created, setCreated] = useState(false);

  if (showSuccessAlert || showDengerAlert) {
    setTimeout(function () {
      setShowSuccessAlert(false);
      setShowDengerAlert(false);
      setLoading(false);
      if (showSuccessAlert) {
        setCreated(true);
      }
    }, 2000);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const res = await createAnotherInvoice(invoiceData, user);
    if (res.status === 200) {
      setShowSuccessAlert(true);
      setText(res.data.message);
    } else {
      setShowDengerAlert(true);
      setText(res.data.ERROR);
    }
  };

  const handleInputChange = (e) => {
    const target = e.target;
    let value;
    if (target.type === "checkbox") {
      value = target.checked;
    } else if (target.type === "number") {
      value = parseFloat(target.value);
    } else {
      value = target.value;
    }

    const name = target.name;
    setInvoiceData({ ...invoiceData, [name]: value });
  };

  useEffect(() => {
    // console.log(id);
    const fetchData = async () => {
      const data = await GetStInvNum(id);
      // console.log(data.invoice_number[0].invoice_number);
      if (data) {
        const invoice_number = data.invoice_number[0].invoice_number;
        setInvoiceNum(invoice_number);
      }
    };

    fetchData();
  }, [id]);

  return (
    <div>
      <form
        onSubmit={handleSubmit}
        className="p-2 border rounded-lg my-2 shadow"
      >
        <div className="w-full flex items-center justify-center mb-4">
          {showSuccessAlert && <AlertSuccess text={text} />}
          {showDengerAlert && <AlertDenger text={text} />}
          <h1 className="text-gray-900 font-medium text-lg">Invoice</h1>
        </div>
        <div className="text-center pb-4 text-lg">
          Invoice Number : {invoiceNum}
        </div>
        <div className="p-1 border rounded-lg">
          <Input
            type="checkbox"
            placeholder="ACT1"
            name="act1"
            required={false}
            onChange={handleInputChange}
            value={invoiceData.act1}
          />
          {invoiceData.act1 && (
            <>
              <div className="m-2 flex items-center justify-center">
                <label htmlFor="act_subject2" className="flex-auto">
                  <span className="text-red-600">*</span>
                  Exam Name:
                </label>
                <select
                  className="flex-initial p-2 ml-1 border rounded-xl focus:outline-blue-700"
                  onChange={handleInputChange}
                  name="exam_name"
                  value={invoiceData.exam_name}
                >
                  {exam_name.map((exam, i) => (
                    <option value={exam} key={i}>
                      {exam}
                    </option>
                  ))}
                </select>
              </div>
              <Input
                placeholder="No. of Trials ACT1"
                type="text"
                name="act1_try"
                value={invoiceData.act1_try}
                onChange={handleInputChange}
              />
              <Input
                placeholder="Month"
                type="text"
                name="month"
                value={invoiceData.month}
                onChange={handleInputChange}
              />
              <Input
                placeholder="Year"
                type="text"
                name="year"
                value={invoiceData.year}
                onChange={handleInputChange}
              />
              <div className="flex justify-between">
                <p className="p-2 pr-6">ACT1 Crt Number: </p>
                <div className="flex">
                  <Input
                    placeholder="No Need"
                    type="radio"
                    name="act1Crt"
                    value="0"
                    onChange={handleInputChange}
                  />
                  <Input
                    placeholder="Need One"
                    type="radio"
                    name="act1Crt"
                    value="1"
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <Input
                placeholder="ACT1 Copies Number"
                type="number"
                name="act1_copy_number"
                value={invoiceData.act1_copy_number}
                onChange={handleInputChange}
              />
            </>
          )}
        </div>
        <div className="mt-2 p-1 border rounded-lg">
          <Input
            type="checkbox"
            placeholder="ACT2 Subject"
            name="act2"
            required={false}
            onChange={handleInputChange}
            value={invoiceData.act2}
          />
          {invoiceData.act2 && (
            <>
              <Input
                placeholder="ACT2 ID/Email"
                type="text"
                name="act2_id"
                value={invoiceData.act2_id}
                onChange={handleInputChange}
                required={false}
              />
              <Input
                placeholder="No. of Trials ACT2"
                type="text"
                name="act2_try"
                value={invoiceData.act2_try}
                onChange={handleInputChange}
              />
              <Input
                placeholder="ACT2 Subject Month"
                type="text"
                name="act2_month"
                value={invoiceData.act2_month}
                onChange={handleInputChange}
              />
              <Input
                placeholder="ACT2 Subject Year"
                type="text"
                name="act2_year"
                value={invoiceData.act2_year}
                onChange={handleInputChange}
              />
              <Input
                placeholder="ACT2 Subject "
                type="text"
                name="subject"
                value={invoiceData.subject}
                onChange={handleInputChange}
              />
              <div className="flex justify-between">
                <p className="p-2 pr-6">ACT2 Crt Number: </p>
                <div className="flex">
                  <Input
                    placeholder="No Need"
                    type="radio"
                    name="act2Crt"
                    value="0"
                    onChange={handleInputChange}
                  />
                  <Input
                    placeholder="Need One"
                    type="radio"
                    name="act2Crt"
                    value="1"
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <Input
                placeholder="ACT2 Copies Number"
                type="number"
                name="act2_copy_number"
                value={invoiceData.act2_copy_number}
                onChange={handleInputChange}
              />
            </>
          )}
        </div>
        <div className="mt-2 p-1 border rounded-lg">
          {invoiceData.act1 === true || invoiceData.act2 === true ? (
            <>
              <Input
                type="checkbox"
                placeholder="MOE Auth"
                name="moe_auth"
                required={false}
                onChange={handleInputChange}
                value={invoiceData.moe_auth}
              />
              {invoiceData.moe_auth && (
                <>
                  {invoiceData.act1 ? (
                    <>
                      <div className="flex justify-between">
                        <p className="p-2 pr-6">Moe ACT1 Number: </p>
                        <div className="flex">
                          <Input
                            placeholder="No Need"
                            type="radio"
                            name="moe1_number"
                            value="0"
                            onChange={handleInputChange}
                          />
                          <Input
                            placeholder="Need One"
                            type="radio"
                            name="moe1_number"
                            value="1"
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                      <Input
                        placeholder="MOE ACT1 Copies Number"
                        type="number"
                        name="moe1_copy_number"
                        value={invoiceData.moe1_copy_number}
                        onChange={handleInputChange}
                      />
                    </>
                  ) : null}
                  {invoiceData.act2 ? (
                    <>
                      <div className="flex justify-between">
                        <p className="p-2 pr-6">Moe ACT2 Number: </p>
                        <div className="flex">
                          <Input
                            placeholder="No Need"
                            type="radio"
                            name="moe2_number"
                            value="0"
                            onChange={handleInputChange}
                          />
                          <Input
                            placeholder="Need One"
                            type="radio"
                            name="moe2_number"
                            value="1"
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                      <Input
                        placeholder="MOE ACT2 Copies Number"
                        type="number"
                        name="moe2_copy_number"
                        value={invoiceData.moe2_copy_number}
                        onChange={handleInputChange}
                      />
                    </>
                  ) : null}
                </>
              )}
            </>
          ) : (
            ""
          )}
        </div>
        <Input
          placeholder="Admin Fees"
          type="number"
          name="admin_fees"
          value={invoiceData.admin_fees}
          onChange={handleInputChange}
          float={true}
        />
        <Input
          placeholder="shipping"
          type="number"
          name="shipping"
          value={invoiceData.shipping}
          onChange={handleInputChange}
        />

        <Input
          placeholder="Discount"
          type="number"
          name="discount"
          value={invoiceData.discount}
          onChange={handleInputChange}
        />
        <div className="flex justify-between">
          <p className="p-2 pr-6">Invoice Type: </p>
          <div className="flex">
            <Input
              placeholder="Increase"
              type="radio"
              name="invoice_type"
              value="increase"
              onChange={handleInputChange}
            />
            <Input
              placeholder="Refund"
              type="radio"
              name="invoice_type"
              value="refund"
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className="inline-flex rounded-md shadow-sm text-sm lg:text-base md:text-base">
          <button
            className="py-2 px-3 text-gray-900 bg-white rounded-l-lg border 
                       font-medium hover:text-blue-700 disabled:bg-gray-50
                       disabled:hover:text-gray-900 disabled:cursor-not-allowed"
            type="submit"
            disabled={loading}
          >
            {!loading ? "Create" : <Loading />}
          </button>
          <button
            className="py-2 px-3 text-gray-900 bg-white rounded-r-lg border 
                       font-medium hover:text-blue-700 disabled:bg-gray-50
                       disabled:hover:text-gray-900 disabled:cursor-not-allowed"
            disabled={!created}
          >
            {!created ? (
              "Download Main"
            ) : (
              <a
                href={`${url}/invoice/anothermain/download/${id}`}
                target="_blank"
                onClick={() => {
                  // setCreated(false);
                }}
                rel="noreferrer"
              >
                Download Main
              </a>
            )}
          </button>
        </div>
      </form>
    </div>
  );
}
