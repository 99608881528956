export default function InfoRow({ data, label, about }) {
  return (
    <div className="">
      <label className="font-light" htmlFor={about}>
        {label}:&nbsp;
      </label>
      <span about={about} className="">
        {data}
      </span>
    </div>
  );
}
