import React, { useState } from "react";
import Button from "../../components/Button";
import Loading from "../../components/Loading";
import { submit } from "../../actions/studensts";
import { useNavigate } from "react-router-dom";
import Input from "../../components/Input";
import TermsAndConditions from "./terms-and-conditions";
import { Container } from "react-bootstrap";

const studentInitialData = {
  id: "",
  full_name: "",
  email: "",
  phone: "",
};

export default function Form() {
  const [studentData, setStudentData] = useState(studentInitialData);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const date = formatDate(new Date(Date.now()));
    const res = await submit(studentData, date);
    console.log(res);
    setLoading(false);
    if (res !== 201) {
      navigate("/redirect", { state: { request: "bad" } });
    } else {
      navigate("/redirect", { state: { request: "good" } });
    }
  };

  const handleInputChange = async (e) => {
    const target = e.target;
    let value;
    if (target.type === "checkbox") {
      value = target.checked;
    } else if (target.type === "number") {
      const int_value = parseInt(target.value);
      value = isNaN(int_value) ? 0 : int_value;
    } else {
      value = target.value;
    }

    const name = target.name;
    setStudentData({ ...studentData, [name]: value });
  };

  return (
    <Container className="flex justify-center items-center">
      <form
        onSubmit={handleSubmit}
        action="submit"
        className="flex flex-col lg:w-3/4 md:w-3/4 w-full p-7 my-2 lg:shadow 
            bg-white bg-opacity-90 lg:border-none sm:rounded-none md:border md:rounded-lg"
      >
        <p className="font-bold text-center">
          <span className="text-red-700">Note:</span>
          <br />
          For Best Experience We Recommend to
          <br />
          Use Google Chrome or Firefox Browsers,
          <br />
          and if you use an Apple Product, Please
          <br />
          Make Sure that Safari Version is 12.0 or Higher.
        </p>
        <div className="border-b m-2">
          <label htmlFor="form" className="text-xl font-bold">
            Add Your Information
          </label>
        </div>
        <Input
          type="text"
          placeholder="Full Name"
          name="full_name"
          onChange={handleInputChange}
          value={studentData.full_name}
          pattern="^[a-zA-Z0-9\-\u0590-\u05FF ]+$"
          title="Please enter english language only"
        />
        <Input
          type="text"
          placeholder="National ID"
          name="id"
          onChange={handleInputChange}
          value={studentData.id}
        />
        <Input
          type="email"
          placeholder="Email"
          name="email"
          onChange={handleInputChange}
          value={studentData.email}
        />
        <Input
          type="number"
          placeholder="Phone"
          name="phone"
          onChange={handleInputChange}
          value={studentData.phone}
        />

        <div className="m-2 mt-3 flex items-center justify-center text-right">
          <input
            autoComplete="off"
            type="checkbox"
            className="flex-initial p-2 ml-1 border rounded-xl focus:outline-blue-700"
            required={true}
          />
          <label htmlFor="send score" className="flex-auto dir-pthree">
            <span className="text-red-600 ">*</span>
            <span className="text-base font-medium">نرجوا التاكد من عمل </span>
            <span>
              <a
                className="text-blue-700 underline"
                href="https://www.act.org/content/act/en/products-and-services/the-act/scores/sending-your-scores.html"
                target="_blank"
                rel="noreferrer"
              >
                Send score
              </a>
            </span>
            <span className="text-base font-medium">
              &nbsp;للمحاولات المطلوبة&nbsp;
            </span>
          </label>
        </div>

        <div>
          <TermsAndConditions />
          <div>
            <Input type="text" placeholder="Printed Full Name" />
            <Input type="date" placeholder="Date" />
            <Input type="text" placeholder="Signature" />
            <Input type="text" placeholder="Egyptian ID Number" />
            <Input type="text" placeholder="Passport Number" required={false} />
          </div>
        </div>

        <div className="flex flex-row-reverse items-center justify-between mt-3 pt-2">
          <Button
            type="submit"
            color="bg-green-700 disabled:bg-gray-300"
            disabled={loading}
            text={!loading ? "Submit" : <Loading />}
          />
        </div>
        <span className="text-red-700 text-sm">*required field</span>
      </form>
    </Container>
  );
}

function formatDate(today) {
  return today?.toISOString().split("T")[0];
}
