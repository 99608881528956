import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { getUsers } from "../actions/studensts";
import StudentLink from "./StudentLink";

export default function StudentData({ students }) {
  const [allUsers, setAllUsers] = useState([]);
  const [selected, setSelected] = useState("all");
  const user = JSON.parse(localStorage.getItem("profile")).value[0];
  const pathname_one = useLocation().pathname.split("/")[2];

  const handleSelect = async (username) => {
    let newSelected = selected;
    newSelected = username;
    setSelected(newSelected);
  };

  const getAllUsers = async () => {
    const { data } = await getUsers();
    if (data) {
      const result = data.users;
      setAllUsers(result);
    }
  };

  function Filtered() {
    const result = students?.filter(
      (student) => student.invoice_user === selected,
    );
    return (
      <>
        <span
          className="mt-0 mb-5 p-3 w-14 h-14 flex items-center justify-center 
              rounded-full text-sm text-green-700 bg-green-100"
        >
          {result?.length}
        </span>

        {result?.map((element) => (
          <StudentLink element={element} key={element.id} />
        ))}
        {students?.length === 0 && (
          <h1
            className="w-full my-2 border py-2.5 px-5 rounded-lg bg-gray-50
          flex items-center justify-between text-sm font-medium text-red-600"
          >
            No Student Available...
          </h1>
        )}
      </>
    );
  }

  function DataFiltered() {
    const result = students?.filter(
      (student) => student.invoice_user === user.username,
    );
    return (
      <>
        <span
          className="mt-0 mb-5 p-3 w-14 h-14 flex items-center justify-center 
              rounded-full text-sm text-green-700 bg-green-100"
        >
          {result?.length}
        </span>

        {result?.map((element) => (
          <StudentLink element={element} key={element.id} />
        ))}
        {students?.length === 0 && (
          <h1
            className="w-full my-2 border py-2.5 px-5 rounded-lg bg-gray-50
          flex items-center justify-between text-sm font-medium text-red-600"
          >
            No Student Available...
          </h1>
        )}
      </>
    );
  }

  useEffect(() => {
    getAllUsers();
  }, []);

  return (
    <>
      {user.role === "user" && pathname_one !== "auditing" ? (
        <DataFiltered />
      ) : (
        <>
          {pathname_one === "auditing" && user.role !== "admin" ? (
            <span
              className="mt-0 mb-5 p-3 w-14 h-14 flex items-center justify-center 
              rounded-full text-sm text-green-700 bg-green-100"
            >
              {students?.length}
            </span>
          ) : (
            ""
          )}
          {pathname_one !== "auditing" && (
            <div className="p-2">
              <label htmlFor="users" className="pr-2">
                Select Username:
              </label>
              <select
                className="flex-initial p-2 border rounded-xl focus:outline-blue-700"
                onChange={(e) => handleSelect(e.target.value)}
                id="users"
              >
                <option value="all">All</option>
                {allUsers?.map((user, i) => (
                  <option value={user.username} key={i}>
                    {user.username}
                  </option>
                ))}
              </select>
            </div>
          )}
          {selected === "all" ? (
            <>
              {students?.map((element) => (
                <StudentLink element={element} key={element.id} />
              ))}
              {students?.length === 0 && (
                <h1
                  className="w-full my-2 border py-2.5 px-5 rounded-lg bg-gray-50
                  flex items-center justify-between text-sm font-medium text-red-600"
                >
                  No Student Available...
                </h1>
              )}
            </>
          ) : (
            <Filtered />
          )}
        </>
      )}
    </>
  );
}
