import { CLEAR, FETCH_ALL } from "../constants/actionTypes";
import * as api from "../api/index";

export const getAll = (page, search, edit) => async (dispatch) => {
  try {
    dispatch({ type: CLEAR });
    const { data } = await api.getAll(page, search, edit);
    dispatch({ type: FETCH_ALL, payload: data });
  } catch (error) {
    return error.response;
  }
};

export const getType = (user, type, search) => async (dispatch) => {
  try {
    dispatch({ type: CLEAR });
    const { data } = await api.getType(user, type, search);
    dispatch({ type: FETCH_ALL, payload: data });
  } catch (error) {
    dispatch({ type: CLEAR });
    return error.response;
  }
};

export const submit = async (formData, date) => {
  try {
    const data = { submit_date: date, student: { ...formData } };
    const res = await api.submit(data);
    return res.status;
  } catch (error) {
    return error.response.status;
  }
};

export const getUsers = async () => {
  try {
    const res = await api.getUsers();
    return res;
  } catch (error) {
    return error.response.status;
  }
};

export const createUser = async (userData) => {
  try {
    const res = await api.createUser(userData);
    return res;
  } catch (error) {
    return error.response;
  }
};

export const forword = async (student_id, from, to, user_action) => {
  try {
    const res = await api.forward(student_id, from, to, user_action);
    return res;
  } catch (error) {
    return error.response;
  }
};

export const forwordwithcount = async (
  to,
  student_id,
  user_action,
  increase
) => {
  try {
    const res = await api.forwardWithCount(
      to,
      student_id,
      user_action,
      increase
    );
    return res;
  } catch (error) {
    return error.response;
  }
};

export const sendIssue = async (id, request_edit_user, request_edit) => {
  try {
    const res = await api.sendAuditing(id, request_edit_user, request_edit);
    return res;
  } catch (error) {
    return error.response;
  }
};

export const sendInvoice = async (id, invoice_wait_user, invoice) => {
  try {
    const res = await api.sendInvoice(id, invoice_wait_user, invoice);
    return res;
  } catch (error) {
    return error.response;
  }
};

export const sendScore = async () => {
  try {
    const res = await api.sendScore();
    return res;
  } catch (error) {
    return error.response;
  }
};

export const requestEdit = async (id) => {
  try {
    const res = await api.requestEdit(id);
    return res;
  } catch (error) {
    return error.response;
  }
};

export const acceptEdit = async (id) => {
  try {
    const res = await api.acceptEdit(id);
    return res;
  } catch (error) {
    return error.response;
  }
};

export const editForward = async (student) => {
  try {
    const res = await api.editForword(student);
    return res;
  } catch (error) {
    return error.response;
  }
};

export const updateUnique = async (id) => {
  try {
    const res = await api.updateUnique(id);
    return res;
  } catch (error) {
    return error.response;
  }
};

export const editRequest = async (student) => {
  try {
    const res = await api.editRequest(student);
    return res;
  } catch (error) {
    return error.response;
  }
};

export const resetStudent = async (id) => {
  try {
    const res = await api.resetStudent(id);
    return res;
  } catch (error) {
    return error.response;
  }
};

export const createInvoice = async (invoice_data) => {
  try {
    const res = await api.createInvoice(invoice_data);
    return res;
  } catch (error) {
    return error.response;
  }
};

export const createAnotherInvoice = async (invoice_data, user_action) => {
  try {
    const res = await api.createAnotherInvoice(invoice_data, user_action);
    return res;
  } catch (error) {
    return error.response;
  }
};

export const getInvoiceNumber = async () => {
  try {
    const res = await api.getInvoiceNumber();
    return res;
  } catch (error) {
    return error.response;
  }
};

export const GetStInvNum = async (student_id) => {
  try {
    const res = await api.GetStInvNum(student_id);
    return res.data;
  } catch (error) {
    return error.response;
  }
};

export const updateInvoiceNumber = async (invoice_number) => {
  try {
    const res = await api.updateInvoiceNumber(invoice_number);
    return res;
  } catch (error) {
    return error.response;
  }
};

export const jumpToState = async (id, to, user_action) => {
  try {
    const res = await api.jumpToState(id, to, user_action);
    return res;
  } catch (error) {
    return error.response;
  }
};

export const countState = async () => {
  try {
    const res = await api.countState();
    return res;
  } catch (error) {
    return error.response;
  }
};

export const deleteStudent = async (id) => {
  try {
    const res = await api.deleteStudent(id);
    return res;
  } catch (error) {
    return error.response;
  }
};

export const changeCategory = async (id, category) => {
  try {
    const res = await api.changeCategory(id, category);
    return res;
  } catch (error) {
    return error.response;
  }
};

export const changeCategoryToSchool = async (school_name) => {
  try {
    const res = await api.changeCategoryToSchool(school_name);
    return res;
  } catch (error) {
    return error.response;
  }
};

export const countCategory = async () => {
  try {
    const res = await api.countCategroy();
    return res;
  } catch (error) {
    return error.response;
  }
};

export const exportExcelState = async (state) => {
  try {
    const res = await api.exportExcelState(state);
    return res;
  } catch (error) {
    return error.response;
  }
};

export const exportAllExcel = async () => {
  try {
    const res = await api.exportAllExcel();
    return res;
  } catch (error) {
    return error.response;
  }
};

export const exportExcelMoe = async () => {
  try {
    const res = await api.exportExcelMoe();
    return res;
  } catch (error) {
    return error.response;
  }
};

export const exportExcelFinalMoe = async () => {
  try {
    const res = await api.exportExcelFinalMoe();
    return res;
  } catch (error) {
    return error.response;
  }
};

export const exportExcelAct1 = async () => {
  try {
    const res = await api.exportExcelAct1();
    return res;
  } catch (error) {
    return error.response;
  }
};

export const exportExcelAct2 = async () => {
  try {
    const res = await api.exportExcelAct2();
    return res;
  } catch (error) {
    return error.response;
  }
};

export const scoreComment = async (id, score_pending, score_pending_user) => {
  try {
    const res = await api.scoreComment(id, score_pending, score_pending_user);
    return res;
  } catch (error) {
    return error.response;
  }
};

export const moeComment = async (id, moe_pending, moe_pending_user) => {
  try {
    const res = await api.moeComment(id, moe_pending, moe_pending_user);
    return res;
  } catch (error) {
    return error.response;
  }
};

export const Act1crt = async (id) => {
  try {
    const res = await api.Act1crt(id);
    return res;
  } catch (error) {
    return error.response;
  }
};

export const Act2crt = async (id) => {
  try {
    const res = await api.Act2crt(id);
    return res;
  } catch (error) {
    return error.response;
  }
};

export const Amount = async () => {
  try {
    const res = await api.Amount();
    return res;
  } catch (error) {
    return error.response;
  }
};
