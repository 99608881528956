import { useState, useEffect, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export default function StudentLink({ element }) {
  const [date, setDate] = useState("");
  const pathname_one = useLocation().pathname.split("/")[2];
  const pathname_two = useLocation().pathname.split("/")[3];
  const navigate = useNavigate();

  const getDate = useCallback(() => {
    if (pathname_one === "auditing" && pathname_two === "new") {
      setDate(element.submit_date);
    } else if (pathname_one === "auditing" && pathname_two === "request_edit") {
      setDate(
        element.request_edit_date
          ? element.request_edit_date
          : element.submit_date,
      );
    } else if (pathname_one === "invoice" && pathname_two === "new") {
      setDate(element.invoice_date);
    } else if (pathname_one === "invoice" && pathname_two === "wait") {
      setDate(element.invoice_wait_date);
    } else if (
      pathname_one === "invoice" &&
      pathname_two === "another_invoice"
    ) {
      setDate(
        element.another_invoice_date
          ? element.another_invoice_date
          : element.invoice_date,
      );
    } else if (
      pathname_one === "score" &&
      pathname_two === "score_validation"
    ) {
      setDate(element.score_validation_date);
    } else if (pathname_one === "score" && pathname_two === "new") {
      setDate(element.score_request_date);
    } else if (pathname_one === "score" && pathname_two === "score_pending") {
      setDate(element.score_pending_date);
    } else if (pathname_one === "print" && pathname_two === "new") {
      setDate(element.print_date);
    } else if (
      pathname_one === "print" &&
      pathname_two === "print_validation"
    ) {
      setDate(element.print_validation_date);
    } else if (pathname_one === "moe" && pathname_two === "new") {
      setDate(element.moe_date);
    } else if (pathname_one === "deliver") {
      setDate(element.deliver_date);
    } else if (pathname_one === "mohe" && pathname_two === "mohe_validation") {
      setDate(element.mohe_validation_date);
    } else if (pathname_one === "mohe" && pathname_two === "new") {
      setDate(element.mohe_date);
    } else if (pathname_one === "done") {
      setDate(element.done_date);
    } else {
      setDate(element.submit_date);
    }
  }, [pathname_one, pathname_two, element]);

  useEffect(() => {
    getDate();
    // console.log(element);
  }, [getDate]);

  return (
    <div
      className="w-full my-2 border py-2.5 px-5 rounded-lg hover:bg-gray-50
          flex flex-wrap items-center justify-between cursor-pointer text-sm font-medium"
      onClick={() => {
        navigate(element.id.toString(), { state: element });
      }}
    >
      <p className="md:w-3/5 w-full">{element.full_name}</p>
      {element.invoice_user ? (
        <p className="bg-lime-300 w-32 text-center p-1 rounded-lg">
          {element.invoice_user}
        </p>
      ) : (
        ""
      )}
      <p className="bg-lime-600 w-24 text-center p-1 rounded-lg">{date}</p>
    </div>
  );
}
