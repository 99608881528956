import { useLocation, useNavigate } from "react-router-dom";
import Button from "../../components/Button";
import { sendInvoice } from "../../actions/studensts";
import { useState } from "react";
import AlertDenger from "../../components/AlertDenger";
import AlertSuccess from "../../components/AlertSuccess";
import Loading from "../../components/Loading";

export default function SendMailInv() {
  const user = JSON.parse(localStorage.getItem("profile")).value[0].username;
  const navigate = useNavigate();
  const id = useLocation().state;
  const [text, setText] = useState("");
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showDengerAlert, setShowDengerAlert] = useState(false);
  const [loading, setLoading] = useState(false);

  if (showSuccessAlert || showDengerAlert) {
    setTimeout(function () {
      setShowSuccessAlert(false);
      setShowDengerAlert(false);
      setLoading(false);
      // navigate("/main/invoice/wait");
    }, 2000);
  }

  const handleSend = async () => {
    const res = await sendInvoice(id, user);
    if (res.status === 200) {
      setShowSuccessAlert(true);
      setText(res.data.message);
    } else {
      setShowDengerAlert(true);
      setText(res.data.ERROR);
    }
  };

  return (
    <div className="flex justify-center">
      <div
        className="lg:w-3/5 md:w-3/4 w-full min-h-max border shadow
    flex flex-col bg-white rounded-lg p-12"
      >
        {showSuccessAlert && <AlertSuccess text={text} />}
        {showDengerAlert && <AlertDenger text={text} />}
        <div className="flex items-center justify-between pb-12">
          <span
            className="cursor-pointer rounded-lg w-9 h-9 border 
        flex items-center justify-center active:scale-90 hover:bg-gray-200"
            onClick={() => navigate(-1)}
          >
            X
          </span>
        </div>
        <Button
          text={!loading ? "Send to Wait" : <Loading />}
          color="bg-blue-500 disabled:bg-gray-300 mt-4"
          disabled={loading}
          onClick={handleSend}
        />
      </div>
    </div>
  );
}
