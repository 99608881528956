import { useParams } from "react-router-dom";
import Button from "../../components/Button";
import { forword } from "../../actions/studensts";
import AlertDenger from "../../components/AlertDenger";
import AlertSuccess from "../../components/AlertSuccess";
import Loading from "../../components/Loading";
import { useState } from "react";

export default function ActionButtonsValid() {
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showDengerAlert, setShowDengerAlert] = useState(false);
  const [text, setText] = useState("");
  const [loading2, setLoading2] = useState(false);
  const { id } = useParams();

  if (showSuccessAlert || showDengerAlert) {
    setTimeout(function () {
      setShowSuccessAlert(false);
      setShowDengerAlert(false);
      setLoading2(false);
      // navigate("/main/mohe/mohe_validation");
    }, 2000);
  }

  const handleForward = async () => {
    setLoading2(true);
    const res = await forword(
      id,
      "mohe/",
      "mohe",
      JSON.parse(localStorage.getItem("profile"))?.value[0].username
    );
    if (res.status === 200) {
      setShowSuccessAlert(true);
      setText(res.data.message);
    } else {
      setShowDengerAlert(true);
      setText(res.data.ERROR);
    }
  };

  return (
    <>
      <div className="py-3 text-center">
        {showSuccessAlert && <AlertSuccess text={text} />}
        {showDengerAlert && <AlertDenger text={text} />}
        <div className="flex justify-between p-2">
          <div className="p-2">
            <Button
              color="bg-green-500 disabled:bg-gray-300"
              onClick={handleForward}
              disabled={loading2}
              text={!loading2 ? "Forward To MOHE" : <Loading />}
            />
          </div>
        </div>
      </div>
    </>
  );
}
