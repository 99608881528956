import { useLocation, useParams } from "react-router-dom";
import Button from "../../components/Button";
import { Act1crt, Act2crt, forword } from "../../actions/studensts";
import AlertDenger from "../../components/AlertDenger";
import AlertSuccess from "../../components/AlertSuccess";
import Loading from "../../components/Loading";
import { useState } from "react";

export default function ActionButtonsPrint() {
  const { id } = useParams();
  const state = useLocation().state;
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showDengerAlert, setShowDengerAlert] = useState(false);
  const [text, setText] = useState("");
  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [loading3, setLoading3] = useState(false);
  const [createdAct1, setCreatedAct1] = useState(false);
  const [createdAct2, setCreatedAct2] = useState(false);

  const url = "https://americanacademyeg.net/api/v2";
  // const url = "https://english-kingdom.com/api/v2";
  // const url = "http://localhost:5000/api/v2";

  if (showSuccessAlert || showDengerAlert) {
    setTimeout(function () {
      setShowSuccessAlert(false);
      setShowDengerAlert(false);
      setLoading1(false);
      setLoading2(false);
      setLoading3(false);
    }, 2000);
  }

  const createAct1 = async () => {
    setLoading1(true);
    const res = await Act1crt(id);
    if (res.status === 200) {
      setShowSuccessAlert(true);
      setText(res.data.message);
      setCreatedAct1(true);
    } else {
      setShowDengerAlert(true);
      setText(res.data.ERROR);
    }
  };

  const createAct2 = async () => {
    setLoading2(true);
    const res = await Act2crt(id);
    if (res.status === 200) {
      setShowSuccessAlert(true);
      setText(res.data.message);
      setCreatedAct2(true);
    } else {
      setShowDengerAlert(true);
      setText(res.data.ERROR);
    }
  };

  const handleForward = async () => {
    setLoading3(true);
    const res = await forword(
      id,
      "print/",
      "print_validation",
      JSON.parse(localStorage.getItem("profile"))?.value[0].username
    );
    if (res.status === 200) {
      setShowSuccessAlert(true);
      setText(res.data.message);
    } else {
      setShowDengerAlert(true);
      setText(res.data.ERROR);
    }
  };

  return (
    <>
      <div className="py-3 text-center">
        {showSuccessAlert && <AlertSuccess text={text} />}
        {showDengerAlert && <AlertDenger text={text} />}
        <div>
          <div className="flex justify-between p-2">
            <div className="p-2">
              <Button
                color="bg-green-700 disabled:bg-gray-300"
                onClick={createAct1}
                disabled={loading1}
                text={!loading1 ? "Create Act-1 Certificate" : <Loading />}
              />
            </div>
            {state.act2 ? (
              <div className="p-2">
                <Button
                  color="bg-green-700 disabled:bg-gray-300"
                  onClick={createAct2}
                  disabled={loading2}
                  text={!loading2 ? "Create Act-2 Certificate" : <Loading />}
                />
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        <div>
          <div className="p-2">
            <button
              className="py-2 px-3 text-gray-900 bg-white rounded-r-lg border 
                     font-medium hover:text-blue-700 disabled:bg-gray-50
                     disabled:hover:text-gray-900 disabled:cursor-not-allowed"
              disabled={!createdAct1}
            >
              {!createdAct1 ? (
                "Download ACT-1"
              ) : (
                <a
                  href={`${url}/print/act1/download/${id}`}
                  target="_blank"
                  onClick={() => {
                    // setCreated(false);
                  }}
                  rel="noreferrer"
                >
                  Download ACT-1
                </a>
              )}
            </button>
          </div>
          {state.act2 ? (
            <div className="p-2">
              <button
                className="py-2 px-3 text-gray-900 bg-white rounded-r-lg border 
                     font-medium hover:text-blue-700 disabled:bg-gray-50
                     disabled:hover:text-gray-900 disabled:cursor-not-allowed"
                disabled={!createdAct2}
              >
                {!createdAct2 ? (
                  "Download ACT-2"
                ) : (
                  <a
                    href={`${url}/print/act2/download/${id}`}
                    target="_blank"
                    onClick={() => {
                      // setCreated(false);
                    }}
                    rel="noreferrer"
                  >
                    Download ACT-2
                  </a>
                )}
              </button>
            </div>
          ) : (
            ""
          )}
          <div className="p-2">
            <Button
              color="bg-green-500 disabled:bg-gray-300"
              onClick={handleForward}
              disabled={loading3}
              text={!loading3 ? "Forward to Validation" : <Loading />}
            />
          </div>
        </div>
      </div>
    </>
  );
}
