export const heads = [
  { bgColor: "bg-blue-200", text: "unique id" },
  { bgColor: "bg-blue-200", text: "national id" },
  { bgColor: "bg-blue-200", text: "passport number" },
  { bgColor: "bg-yellow-200", text: "full name" },
  { bgColor: "bg-yellow-200", text: "first name" },
  { bgColor: "bg-yellow-200", text: "middle one name" },
  { bgColor: "bg-yellow-200", text: "middle two  name" },
  { bgColor: "bg-yellow-200", text: "last name" },
  { bgColor: "bg-yellow-200", text: "email" },
  { bgColor: "bg-yellow-200", text: "city" },
  { bgColor: "bg-yellow-200", text: "country" },
  { bgColor: "bg-yellow-200", text: "date of birth" },
  { bgColor: "bg-yellow-200", text: "school name" },
  { bgColor: "bg-yellow-200", text: "test center name" },
  { bgColor: "bg-yellow-200", text: "phone" },
  { bgColor: "bg-yellow-200", text: "gender" },
  { bgColor: "bg-green-200", text: "university" },
  { bgColor: "bg-green-200", text: "College 1" },
  { bgColor: "bg-green-200", text: "Aabbreviation 1" },
  { bgColor: "bg-green-200", text: "College 2" },
  { bgColor: "bg-green-200", text: "Aabbreviation 2" },
  { bgColor: "bg-green-200", text: "College 3" },
  { bgColor: "bg-green-200", text: "Aabbreviation 3" },
  { bgColor: "bg-green-200", text: "College 4" },
  { bgColor: "bg-green-200", text: "Aabbreviation 4" },
  { bgColor: "bg-green-200", text: "College 5" },
  { bgColor: "bg-green-200", text: "Aabbreviation 5" },
  { bgColor: "bg-green-200", text: "Specialization 1" },
  { bgColor: "bg-green-200", text: "Specialization 2" },
  { bgColor: "bg-green-200", text: "Specialization 3" },
  { bgColor: "bg-green-200", text: " Year " },
  { bgColor: "bg-green-200", text: "School Contact" },

  { bgColor: "bg-red-400", text: "category" },
  /*------------------------ { exam1 } ------------------------- */
  { bgColor: "bg-gray-400", text: "act 1 id" },
  { bgColor: "bg-gray-400", text: "act1 exam name" },
  { bgColor: "bg-gray-400", text: "act 1 score 1" },
  { bgColor: "bg-gray-400", text: "act 1 score 2" },
  { bgColor: "bg-gray-400", text: "act 1 score 3" },
  { bgColor: "bg-gray-400", text: "act 1 trial date 1" },
  { bgColor: "bg-gray-400", text: "act 1 trial date 2" },
  { bgColor: "bg-gray-400", text: "act 1 trial date 3" },
  { bgColor: "bg-gray-400", text: "act 1 trial date 4" },
  { bgColor: "bg-gray-400", text: "act 1 trial date 5" },
  { bgColor: "bg-gray-400", text: "act 1 no. of copies" },
  { bgColor: "bg-gray-400", text: "act 1 moe no. of copies" },
  { bgColor: "bg-gray-400", text: "act 1 trial 1 id" },
  { bgColor: "bg-gray-400", text: "act 1 trial 2 id" },
  { bgColor: "bg-gray-400", text: "act 1 trial 3 id" },
  { bgColor: "bg-gray-400", text: "act 1 trial 4 id" },
  { bgColor: "bg-gray-400", text: "act 1 trial 5 id" },
  /*------------------------ { exam2 } ------------------------- */
  { bgColor: "bg-blue-400", text: "act 2 id" },
  { bgColor: "bg-blue-400", text: "act 2 score 1" },
  { bgColor: "bg-blue-400", text: "act 2 score 2" },
  { bgColor: "bg-blue-400", text: "act 2 score 3" },
  { bgColor: "bg-blue-400", text: "act 2 trial date 1" },
  { bgColor: "bg-blue-400", text: "act 2 trial date 2" },
  { bgColor: "bg-blue-400", text: "act 2 trial date 3" },
  { bgColor: "bg-blue-400", text: "subject 1" },
  { bgColor: "bg-blue-400", text: "subject 2" },
  { bgColor: "bg-blue-400", text: "subject 3" },
  { bgColor: "bg-blue-400", text: "act 2 no. of copies" },
  { bgColor: "bg-blue-400", text: "act 2 moe no. of copies" },
  { bgColor: "bg-blue-400", text: "act 2 trial 1 id" },
  { bgColor: "bg-blue-400", text: "act 2 trial 2 id" },
  { bgColor: "bg-blue-400", text: "act 2 trial 3 id" },
  /*------------------------ { state & dates } ------------------------- */
  { bgColor: "bg-red-400", text: "state" },
  { bgColor: "bg-gray-200", text: "invoice number" },
  { bgColor: "bg-gray-200", text: "another invoice number" },
  { bgColor: "bg-gray-200", text: "score pending comment" },
  { bgColor: "bg-gray-200", text: "moe pending comment" },
  { bgColor: "bg-gray-200", text: "request edit comment" },
  { bgColor: "bg-gray-200", text: "submit date" },
  { bgColor: "bg-gray-200", text: "request edit date" },
  { bgColor: "bg-gray-200", text: "request edit user" },
  { bgColor: "bg-gray-200", text: "invoice date" },
  { bgColor: "bg-gray-200", text: "invoice user" },
  { bgColor: "bg-gray-200", text: "invoice wait date" },
  { bgColor: "bg-gray-200", text: "invoice wait user" },
  { bgColor: "bg-gray-200", text: "another invoice date" },
  { bgColor: "bg-gray-200", text: "another invoice user" },
  { bgColor: "bg-gray-200", text: "score validation date" },
  { bgColor: "bg-gray-200", text: "score validation user" },
  { bgColor: "bg-gray-200", text: "score request date" },
  { bgColor: "bg-gray-200", text: "score request user" },
  { bgColor: "bg-gray-200", text: "score pending date" },
  { bgColor: "bg-gray-200", text: "score pending user" },
  { bgColor: "bg-gray-200", text: "print date" },
  { bgColor: "bg-gray-200", text: "print user" },
  { bgColor: "bg-gray-200", text: "print validation date" },
  { bgColor: "bg-gray-200", text: "print validation user" },
  { bgColor: "bg-gray-200", text: "moe validation date" },
  { bgColor: "bg-gray-200", text: "moe validation user" },
  { bgColor: "bg-gray-200", text: "moe date" },
  { bgColor: "bg-gray-200", text: "moe user" },
  { bgColor: "bg-gray-200", text: "moe pending date" },
  { bgColor: "bg-gray-200", text: "moe pending user" },
  { bgColor: "bg-gray-200", text: "deliver date" },
  { bgColor: "bg-gray-200", text: "deliver user" },
  { bgColor: "bg-gray-200", text: "mohe validation date" },
  { bgColor: "bg-gray-200", text: "mohe validation user" },
  { bgColor: "bg-gray-200", text: "mohe date" },
  { bgColor: "bg-gray-200", text: "mohe user" },
  { bgColor: "bg-gray-200", text: "done date" },
  { bgColor: "bg-gray-200", text: "done user" },
  { bgColor: "bg-red-400", text: "amount" },
  { bgColor: "bg-gray-200", text: "another amount" },
  { bgColor: "bg-gray-200", text: "amount outside in USD" },
];
