import { useState } from "react";

import { changeCategoryToSchool } from "../../actions/studensts";
import Input from "../../components/Input";
import Button from "../../components/Button";
import AlertDenger from "../../components/AlertDenger";
import AlertSuccess from "../../components/AlertSuccess";
import Loading from "../../components/Loading";
import UNAUTHORIZED from "../UNAUTHORIZED";

export default function GlobalActions() {
  const [schoolName, setSchoolName] = useState("");
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showDengerAlert, setShowDengerAlert] = useState(false);
  const [text, setText] = useState("");
  const [loading, setLoading] = useState(false);
  const user = JSON.parse(localStorage.getItem("profile")).value[0].role;

  if (showSuccessAlert || showDengerAlert) {
    setTimeout(function () {
      setShowSuccessAlert(false);
      setShowDengerAlert(false);
      setLoading(false);
    }, 2000);
  }

  const onSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);
    const res = await changeCategoryToSchool(schoolName);
    if (res.status === 200) {
      setShowSuccessAlert(true);
      setText(res.data.message);
    } else {
      setShowDengerAlert(true);
      setText(res.data.ERROR);
    }
  };

  const onChange = (e) => {
    const target = e.target;
    const value = target.value;
    setSchoolName(value);
  };

  return (
    <div>
      {user !== "admin" ? (
        <UNAUTHORIZED />
      ) : (
        <div className="p-5 flex justify-center">
          {showSuccessAlert && <AlertSuccess text={text} />}
          {showDengerAlert && <AlertDenger text={text} />}
          <div>
            <form
              onSubmit={onSubmit}
              className="rounded-lg p-7 border bg-white shadow"
            >
              <label className="font-semibold">Change Category:</label>
              <Input
                type="text"
                value={schoolName}
                onChange={onChange}
                placeholder="school name"
              />
              <div className="flex justify-end">
                <Button
                  text={loading ? <Loading /> : "Change to School"}
                  color="bg-green-700 hover:bg-green-800 disabled:bg-gray-300"
                  disabled={loading}
                />
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}
