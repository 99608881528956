import axios from "axios";

const API = axios.create({ baseURL: "https://americanacademyeg.net/api/v2" });
// const API = axios.create({ baseURL: "http://localhost:5000/api/v2" }); // development

API.interceptors.request.use((req) => {
  if (localStorage.getItem("profile")) {
    req.headers.Authorization = `Bearer ${
      JSON.parse(localStorage.getItem("profile")).value[0].token
    }`;
  }
  return req;
});

export const logIn = (userData) => API.post("/session", userData);
export const createUser = (userData) => API.post("/user", userData);

export const getAll = (page, search, edit) =>
  API.get("/students", { params: { page, search, edit } });
export const allForViewers = (page, search, edit) =>
  API.get("/studentsforviewers", { params: { page, search, edit } });
export const getType = (user, type, search) =>
  API.get(`/${user}/${type}`, { params: { search } });

export const submit = (data) => API.post("/submit", data);
export const getUsers = () => API.get("/users");

export const forward = (student_id, from, to, user_action) =>
  API.post(`/${from}forward`, { student_id, to, user_action });

export const forwardWithCount = (to, student_id, user_action, increase) =>
  API.post(`/invoice/forwardwithcount`, {
    to,
    student_id,
    user_action,
    increase,
  });

export const sendAuditing = (id, request_edit_user, request_edit) =>
  API.post("/send_issue", { id, request_edit_user, request_edit });

export const sendInvoice = (id, invoice_wait_user, invoice) =>
  API.post("/send_invoice", { id, invoice_wait_user, invoice });

export const sendScore = () => API.post("/send_score");

export const requestEdit = (student_id) =>
  API.post("/request_edit", { student_id });

export const editRequest = (student) =>
  API.put("/edit_in_request", { student });

export const acceptEdit = (student_id) =>
  API.post("/accept_edit", { student_id });

export const editForword = (student) => API.put("/edit", { student });

export const updateUnique = (id) => API.post("/update_unique", { id });

export const resetStudent = (student_id) => API.post("/reset", { student_id });

export const createInvoice = (invoice_data) =>
  API.post("/invoice/create", { invoice_data });

export const createAnotherInvoice = (invoice_data, user_action) =>
  API.post("/invoice/createanother", { invoice_data, user_action });

export const getInvoiceNumber = () => API.get("/invoice/number");

export const GetStInvNum = (student_id) =>
  API.post("/invoice/stinvnum", { student_id: student_id });

export const updateInvoiceNumber = (invoice_number) =>
  API.post("/invoice/number", { invoice_number });

export const jumpToState = (student_id, to, user_action) =>
  API.post("/jumpto", { student_id, to, user_action });

export const countState = () => API.get("/count");

export const deleteStudent = (student_id) =>
  API.delete("/delete/" + student_id);

export const changeCategory = (student_id, category) =>
  API.patch("/category", { student_id, category });

export const changeCategoryToSchool = (school_name) =>
  API.patch("/category/school", { school_name });

export const countCategroy = () => API.get("/category");

export const exportExcelState = (state) => API.post("/excelstate", { state });

export const exportAllExcel = () => API.get("/excel");

export const exportExcelMoe = () => API.get("/excelmoe");

export const exportExcelFinalMoe = () => API.get("/excelfinalmoe");

export const exportExcelAct1 = () => API.get("/excelactone");

export const exportExcelAct2 = () => API.get("/excelacttwo");

export const scoreComment = (id, score_pending, score_pending_user) =>
  API.post("/score_comment", { id, score_pending, score_pending_user });

export const moeComment = (id, moe_pending, moe_pending_user) =>
  API.post("/moe_comment", { id, moe_pending, moe_pending_user });

export const Act1crt = (id) => API.post("/printact1", { id });
export const Act2crt = (id) => API.post("/printact2", { id });
export const Amount = () => API.get("/amount");
