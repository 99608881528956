import { useState } from "react";
import { useLocation } from "react-router-dom";
import Button from "../components/Button";
import { editForward, editRequest, updateUnique } from "../actions/studensts";
import AlertDenger from "../components/AlertDenger";
import AlertSuccess from "../components/AlertSuccess";
import Loading from "../components/Loading";
import Input from "../components/Input";
import { compressAccurately } from "image-conversion";
import { ACT_SUBJECTS, EXAM_NAMES } from "../constants/subjects-list";

export default function Edit() {
  const user = JSON.parse(localStorage.getItem("profile"));
  const state = useLocation().state;
  console.log(state);
  const pathname = useLocation().pathname.split("/")[3];
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showDengerAlert, setShowDengerAlert] = useState(false);
  const [text, setText] = useState("");
  const [loading, setLoading] = useState(false);
  const [studentData, setStudentData] = useState({
    university: "",
    ...state,
    old_id: state.id,
    edited_user: user.value[0].username,
    request_edit_user: user.value[0].username,
    image_letter: "",
    image_id: "",
  });
  const pathname_two = useLocation().pathname.split("/")[3];

  if (showSuccessAlert || showDengerAlert) {
    setTimeout(function () {
      setShowSuccessAlert(false);
      setShowDengerAlert(false);
      setLoading(false);
      if (pathname === "new") {
        // navigate("/main/invoice/new");
      } else if (pathname === "request_edit") {
        // navigate("/main/auditing/request_edit");
      }
    }, 3000);
  }

  const handleClick = async (e) => {
    e.preventDefault();

    setLoading(true);
    let res;
    if (pathname === "new") {
      res = await editForward(studentData);
      await updateUnique(studentData.old_id);
    } else if (pathname === "request_edit") {
      res = await editRequest(studentData);
    }
    if (res.status === 200) {
      setShowSuccessAlert(true);
      setText(res.data.message);
    } else {
      setShowDengerAlert(true);
      setText(res.data.ERROR);
    }
  };

  const handleInputChange = async (e) => {
    const target = e.target;
    let value;
    if (target.type === "checkbox") {
      value = target.checked;
    } else if (target.type === "file") {
      const file = target.files[0];
      const compressedImage = await compressAccurately(file, 100);
      value = await convertToBase64(compressedImage);
    } else {
      value = target.value;
    }

    const name = target.name;
    setStudentData({ ...studentData, [name]: value });
  };

  return (
    <div className="flex justify-center">
      <div
        className="lg:w-4/5 font-serif shadow-2xl opacity-90	 md:w-3/4 w-full min-h-screen border shadow
    flex flex-col bg-slate-300 p-2 rounded-lg"
      >
        {showSuccessAlert && <AlertSuccess text={text} />}
        {showDengerAlert && <AlertDenger text={text} />}
        <form
          onSubmit={handleClick}
          action="submit"
          className="m-3 border rounded-md p-3 shadow"
        >
          <div className="p-1 ">
            <Input
              type="text"
              placeholder="Full Name"
              name="full_name"
              onChange={handleInputChange}
              value={studentData.full_name}
              pattern="^[a-zA-Z0-9\-\u0590-\u05FF ]+$"
              title="Please enter english language only"
            />
            <Input
              type="text"
              placeholder="First Name"
              name="first_name"
              onChange={handleInputChange}
              value={studentData.first_name}
              pattern="^[a-zA-Z0-9\-\u0590-\u05FF ]+$"
              title="Please enter english language only"
            />
            <Input
              type="text"
              placeholder="Middle 1 Name"
              name="middle1_name"
              onChange={handleInputChange}
              value={studentData.middle1_name}
              required={false}
              pattern="^[a-zA-Z0-9\-\u0590-\u05FF ]+$"
              title="Please enter english language only"
            />
            <Input
              type="text"
              placeholder="Middle 2 Name"
              name="middle2_name"
              onChange={handleInputChange}
              value={studentData.middle2_name}
              required={false}
              pattern="^[a-zA-Z0-9\-\u0590-\u05FF ]+$"
              title="Please enter english language only"
            />
            <Input
              type="text"
              placeholder="Last Name"
              name="last_name"
              onChange={handleInputChange}
              value={studentData.last_name}
              pattern="^[a-zA-Z0-9\-\u0590-\u05FF ]+$"
              title="Please enter english language only"
            />
            {pathname_two === "new" ? (
              <Input
                type="text"
                placeholder="National ID"
                name="id"
                disabled
                onChange={handleInputChange}
                value={studentData.id}
              />
            ) : (
              <Input
                type="text"
                placeholder="National ID"
                name="id"
                onChange={handleInputChange}
                value={studentData.id}
              />
            )}

            <Input
              type="text"
              placeholder="Passport Number"
              name="passport_number"
              required={false}
              onChange={handleInputChange}
              value={studentData.passport_number}
            />
            <Input
              type="text"
              placeholder="ACT ID"
              name="act1_id"
              onChange={handleInputChange}
              value={studentData.act1_id}
            />
            <Input
              type="text"
              placeholder="ACT Subject ID"
              name="act2_id"
              onChange={handleInputChange}
              required={false}
              value={studentData.act2_id}
            />
            <Input
              type="text"
              placeholder="Email"
              name="email"
              onChange={handleInputChange}
              value={studentData.email}
            />
            <Input
              type="text"
              placeholder="Phone"
              name="phone"
              onChange={handleInputChange}
              value={studentData.phone}
            />
            <Input
              type="date"
              placeholder="Date of Birth"
              name="date_of_birth"
              onChange={handleInputChange}
              value={studentData.date_of_birth}
            />
            <Input
              type="text"
              placeholder="City"
              name="address"
              onChange={handleInputChange}
              value={studentData.address}
              pattern="^[a-zA-Z0-9\-\u0590-\u05FF ]+$"
              title="Please enter english language only"
            />
            <Input
              type="text"
              placeholder="Country"
              name="postal_code"
              onChange={handleInputChange}
              value={studentData.postal_code}
            />
            <div className="m-2 flex items-center justify-center">
              <label htmlFor="name" className="flex-auto">
                <span className="text-red-600">*</span>
                Gender:
              </label>
              <div className="flex-1 flex items-center justify-between">
                <div className="flex gap-1">
                  <input
                    type="radio"
                    name="gender"
                    value="male"
                    required
                    onChange={handleInputChange}
                    checked={studentData.gender === "male"}
                  />
                  <label htmlFor="male">Male</label>
                </div>
                <div className="flex gap-1">
                  <input
                    type="radio"
                    name="gender"
                    value="female"
                    onChange={handleInputChange}
                    checked={studentData.gender === "female"}
                  />
                  <label htmlFor="female">Female</label>
                </div>
              </div>
            </div>
            <div className="m-2 flex items-center justify-center">
              <label htmlFor="name" className="flex-auto">
                <span className="text-red-600">*</span>
                University:
              </label>
              <div className="flex-1 flex items-center justify-between">
                <div className="flex gap-1">
                  <input
                    type="radio"
                    name="university"
                    value="governmental"
                    required
                    onChange={handleInputChange}
                    checked={studentData.university === "governmental"}
                  />
                  <label htmlFor="governmental">Governmental</label>
                </div>
                <div className="flex gap-1">
                  <input
                    type="radio"
                    name="university"
                    value="private"
                    onChange={handleInputChange}
                    checked={studentData.university === "private"}
                  />
                  <label htmlFor="both">Private</label>
                </div>
                <div className="flex gap-1">
                  <input
                    type="radio"
                    name="university"
                    value="both"
                    onChange={handleInputChange}
                    checked={studentData.university === "both"}
                  />
                  <label htmlFor="both">Both</label>
                </div>
              </div>
            </div>
            <div className="m-2 flex items-center justify-center">
              <div className="flex-1 flex items-center justify-between p-1 m-1 border border-gray-400 rounded-md">
                <label htmlFor="collegeName1" className="flex-auto">
                  <span className="text-red-600">*</span>
                  College Name 1:
                </label>
                <div className="flex gap-1">
                  <input
                    type="text"
                    name="collegeName1"
                    value={studentData.collegeName1}
                    onChange={handleInputChange}
                    placeholder="Enter College Name"
                    required
                  />
                </div>
              </div>

              <div className="flex-1 flex items-center justify-between p-1 m-1 border border-gray-400 rounded-md">
                <label htmlFor="colabbreviation1" className="flex-auto">
                  <span className="text-red-600">*</span>
                  Abbreviation:
                </label>
                <div className="flex gap-1">
                  <input
                    type="text"
                    name="colabbreviation1"
                    value={studentData.colabbreviation1}
                    onChange={handleInputChange}
                    placeholder="Enter College Abbreviation"
                    required
                  />
                </div>
              </div>
            </div>
            <div className="m-2 flex items-center justify-center">
              <div className="flex-1 flex items-center justify-between p-1 m-1 border border-gray-400 rounded-md">
                <label htmlFor="collegeName2" className="flex-auto">
                  College Name 2:
                </label>
                <div className="flex gap-1">
                  <input
                    type="text"
                    name="collegeName2"
                    value={studentData.collegeName2}
                    onChange={handleInputChange}
                    placeholder="Enter College Name"
                  />
                </div>
              </div>

              <div className="flex-1 flex items-center justify-between p-1 m-1 border border-gray-400 rounded-md">
                <label htmlFor="colabbreviation2" className="flex-auto">
                  Abbreviation:
                </label>
                <div className="flex gap-1">
                  <input
                    type="text"
                    name="colabbreviation2"
                    value={studentData.colabbreviation2}
                    onChange={handleInputChange}
                    placeholder="Enter College Abbreviation"
                  />
                </div>
              </div>
            </div>
            <div className="m-2 flex items-center justify-center">
              <div className="flex-1 flex items-center justify-between p-1 m-1 border border-gray-400 rounded-md">
                <label htmlFor="collegeName3" className="flex-auto">
                  College Name 3:
                </label>
                <div className="flex gap-1">
                  <input
                    type="text"
                    name="collegeName3"
                    value={studentData.collegeName3}
                    onChange={handleInputChange}
                    placeholder="Enter College Name"
                  />
                </div>
              </div>

              <div className="flex-1 flex items-center justify-between p-1 m-1 border border-gray-400 rounded-md">
                <label htmlFor="colabbreviation3" className="flex-auto">
                  Abbreviation:
                </label>
                <div className="flex gap-1">
                  <input
                    type="text"
                    name="colabbreviation3"
                    value={studentData.colabbreviation3}
                    onChange={handleInputChange}
                    placeholder="Enter College Abbreviation"
                  />
                </div>
              </div>
            </div>
            <div className="m-2 flex items-center justify-center">
              <div className="flex-1 flex items-center justify-between p-1 m-1 border border-gray-400 rounded-md">
                <label htmlFor="collegeName4" className="flex-auto">
                  College Name 4:
                </label>
                <div className="flex gap-1">
                  <input
                    type="text"
                    name="collegeName4"
                    value={studentData.collegeName4}
                    onChange={handleInputChange}
                    placeholder="Enter College Name"
                  />
                </div>
              </div>

              <div className="flex-1 flex items-center justify-between p-1 m-1 border border-gray-400 rounded-md">
                <label htmlFor="colabbreviation4" className="flex-auto">
                  Abbreviation:
                </label>
                <div className="flex gap-1">
                  <input
                    type="text"
                    name="colabbreviation4"
                    value={studentData.colabbreviation4}
                    onChange={handleInputChange}
                    placeholder="Enter College Abbreviation"
                  />
                </div>
              </div>
            </div>
            <div className="m-2 flex items-center justify-center">
              {/* Input field for entering college name */}
              <div className="flex-1 flex items-center justify-between p-1 m-1 border border-gray-400 rounded-md">
                <label htmlFor="collegeName5" className="flex-auto">
                  College Name 5:
                </label>
                <div className="flex gap-1">
                  <input
                    type="text"
                    name="collegeName5"
                    value={studentData.collegeName5}
                    onChange={handleInputChange}
                    placeholder="Enter College Name"
                  />
                </div>
              </div>

              {/* Input field for entering abbreviation */}
              <div className="flex-1 flex items-center justify-between p-1 m-1 border border-gray-400 rounded-md">
                <label htmlFor="colabbreviation5" className="flex-auto">
                  Abbreviation:
                </label>
                <div className="flex gap-1">
                  <input
                    type="text"
                    name="colabbreviation5"
                    value={studentData.colabbreviation5}
                    onChange={handleInputChange}
                    placeholder="Enter College Abbreviation"
                  />
                </div>
              </div>
            </div>
            <div className="flex-1 flex items-center justify-between p-1 m-1 border border-gray-400 rounded-md">
              <label htmlFor="specialization1" className="flex-auto">
                <span className="text-red-600">*</span>
                Specialization 1:
              </label>
              <div className="flex gap-1">
                <input
                  type="text"
                  name="specialization1"
                  value={studentData.specialization1}
                  onChange={handleInputChange}
                  placeholder="Enter Specialization"
                  required
                />
              </div>
            </div>
            <div className="flex-1 flex items-center justify-between p-1 m-1 border border-gray-400 rounded-md">
              <label htmlFor="specialization2" className="flex-auto">
                Specialization 2:
              </label>
              <div className="flex gap-1">
                <input
                  type="text"
                  name="specialization2"
                  value={studentData.specialization2}
                  onChange={handleInputChange}
                  placeholder="Enter Specialization"
                />
              </div>
            </div>
            <div className="flex-1 flex items-center justify-between p-1 m-1 border border-gray-400 rounded-md">
              <label htmlFor="specialization3" className="flex-auto">
                Specialization 3:
              </label>
              <div className="flex gap-1">
                <input
                  type="text"
                  name="specialization3"
                  value={studentData.specialization3}
                  onChange={handleInputChange}
                  placeholder="Enter Specialization"
                />
              </div>
            </div>
            <div className="flex-1 flex items-center justify-between p-1 m-1 border border-gray-400 rounded-md">
              <label htmlFor="studentyear" className="flex-auto">
                Student Year:
              </label>
              <div className="flex gap-1">
                <input
                  type="text"
                  name="studentyear"
                  value={studentData.studentyear}
                  onChange={handleInputChange}
                  placeholder="Enter Specialization"
                />
              </div>
            </div>
            <div className="flex-1 flex items-center justify-between p-1 border m-1 border-gray-400 rounded-md">
              <label htmlFor="schoolNum" className="flex-auto">
                School Num:
              </label>
              <div className="flex gap-1">
                <input
                  type="text"
                  name="schoolNum"
                  value={studentData.schoolNum}
                  onChange={handleInputChange}
                  placeholder="Enter Specialization"
                />
              </div>
            </div>
            <Input
              type="text"
              placeholder="School Name"
              name="school_name"
              onChange={handleInputChange}
              value={studentData.school_name}
              pattern="^[a-zA-Z0-9\-\u0590-\u05FF ]+$"
              title="Please enter english language only"
            />
            <div className="m-2 flex items-center justify-center">
              <label htmlFor="category" className="flex-auto">
                Category:
              </label>
              <select
                name="category"
                value={studentData.category}
                onChange={handleInputChange}
                className="border border-gray-300 rounded-md px-4 py-2 w-3/4"
              >
                <option value="">Select Category</option>
                <option value="individual">Individual</option>
                <option value="outside">Outside</option>
                {/* <option value="inside">Inside</option> */}
                <option value="foreign">Foreign</option>
                <option value="foreignUsd">Foreign In Dollar</option>
                <option value="individual+1">Individual+1</option>
                <option value="individual+2">Individual+2</option>
                <option value="individual+3">Individual+3</option>
              </select>
            </div>

            <Input
              type="text"
              placeholder="Test Center Name"
              name="test_center_name"
              onChange={handleInputChange}
              required={false}
              value={studentData.test_center_name}
              pattern="^[a-zA-Z0-9\-\u0590-\u05FF ]+$"
              title="Please enter english language only"
            />
            <Input
              type="file"
              onChange={handleInputChange}
              placeholder="Upload Your ID"
              name="image_id"
              required={false}
            />
            <Input
              type="file"
              onChange={handleInputChange}
              placeholder="Upload Enrollment Letter"
              name="image_letter"
              required={false}
            />

            <div className="p-1 border rounded">
              <div className="m-2 flex items-center justify-center">
                <label htmlFor="Exam Name" className="flex-auto">
                  Exam Name:
                </label>
                <select
                  className="flex-initial p-2 ml-1 border rounded-xl focus:outline-blue-700"
                  onChange={handleInputChange}
                  name="exam_name"
                  value={studentData.exam_name}
                  required
                >
                  <option value=""> </option>
                  {EXAM_NAMES.map((subject, i) => (
                    <option value={subject} key={i}>
                      {subject}
                    </option>
                  ))}
                </select>
              </div>
              {studentData.exam_name === "ACT1 Super Score Certificate" && (
                <>
                  <Input
                    type="number"
                    placeholder="Score"
                    name="act1_score1"
                    onChange={handleInputChange}
                    value={studentData.act1_score1}
                  />
                  <Input
                    type="date"
                    placeholder="Trial Date 1"
                    name="act1_try_date1"
                    onChange={handleInputChange}
                    value={studentData.act1_try_date1}
                  />
                  <Input
                    type="text"
                    required={false}
                    placeholder="Trial 1 Id"
                    name="act1_try1_id"
                    onChange={handleInputChange}
                    value={studentData.act1_try1_id}
                  />
                  <Input
                    type="date"
                    placeholder="Trial Date 2"
                    required={false}
                    name="act1_try_date2"
                    onChange={handleInputChange}
                    value={studentData.act1_try_date2}
                  />
                  <Input
                    type="text"
                    placeholder="Trial 2 Id"
                    name="act1_try2_id"
                    onChange={handleInputChange}
                    required={false}
                    value={studentData.act1_try2_id}
                  />
                  <Input
                    type="date"
                    placeholder="Trial Date 3"
                    required={false}
                    name="act1_try_date3"
                    onChange={handleInputChange}
                    value={studentData.act1_try_date3}
                  />
                  <Input
                    type="text"
                    placeholder="Trial 3 Id"
                    name="act1_try3_id"
                    required={false}
                    onChange={handleInputChange}
                    value={studentData.act1_try3_id}
                  />
                  <Input
                    type="date"
                    placeholder="Trial Date 4"
                    required={false}
                    name="act1_try_date4"
                    onChange={handleInputChange}
                    value={studentData.act1_try_date4}
                  />
                  <Input
                    type="text"
                    placeholder="Trial 4 Id"
                    name="act1_try4_id"
                    onChange={handleInputChange}
                    required={false}
                    value={studentData.act1_try4_id}
                  />
                  <Input
                    type="date"
                    placeholder="Trial Date 5"
                    required={false}
                    name="act1_try_date5"
                    onChange={handleInputChange}
                    value={studentData.act1_try_date5}
                  />
                  <Input
                    type="text"
                    required={false}
                    placeholder="Trial 5 Id"
                    name="act1_try5_id"
                    onChange={handleInputChange}
                    value={studentData.act1_try5_id}
                  />
                  <Input
                    type="number"
                    placeholder="Number of Copies "
                    name="act1_copy_number"
                    onChange={handleInputChange}
                    value={studentData.act1_copy_number}
                  />
                </>
              )}{" "}
              {studentData.exam_name === "ACT1 Matching Certificate" && (
                <>
                  <Input
                    type="number"
                    placeholder="Score 1"
                    name="act1_score1"
                    onChange={handleInputChange}
                    value={studentData.act1_score1}
                  />
                  <Input
                    type="date"
                    placeholder="Trial Date 1"
                    name="act1_try_date1"
                    onChange={handleInputChange}
                    value={studentData.act1_try_date1}
                  />
                  <Input
                    type="text"
                    placeholder="Trial 1 Id"
                    name="act1_try1_id"
                    onChange={handleInputChange}
                    required={false}
                    value={studentData.act1_try1_id}
                  />
                  <Input
                    type="number"
                    placeholder="Score 2"
                    name="act1_score2"
                    onChange={handleInputChange}
                    value={studentData.act1_score2}
                    required={false}
                  />
                  <Input
                    type="date"
                    placeholder="Trial Date 2"
                    required={false}
                    name="act1_try_date2"
                    onChange={handleInputChange}
                    value={studentData.act1_try_date2}
                  />
                  <Input
                    type="text"
                    placeholder="Trial 2 Id"
                    name="act1_try2_id"
                    onChange={handleInputChange}
                    value={studentData.act1_try2_id}
                    required={false}
                  />
                  <Input
                    type="number"
                    placeholder="Score 3"
                    name="act1_score3"
                    onChange={handleInputChange}
                    value={studentData.act1_score3}
                    required={false}
                  />
                  <Input
                    type="date"
                    placeholder="Trial Date 3"
                    required={false}
                    name="act1_try_date3"
                    onChange={handleInputChange}
                    value={studentData.act1_try_date3}
                  />
                  <Input
                    type="text"
                    placeholder="Trial 3 Id"
                    required={false}
                    name="act1_try3_id"
                    onChange={handleInputChange}
                    value={studentData.act1_try3_id}
                  />
                  <Input
                    type="number"
                    placeholder="Number of Copies "
                    name="act1_copy_number"
                    onChange={handleInputChange}
                    value={studentData.act1_copy_number}
                  />
                </>
              )}{" "}
              {studentData.exam_name === "ACT1 One Trial Certificate" && (
                <>
                  <Input
                    type="number"
                    placeholder="Score"
                    name="act1_score1"
                    onChange={handleInputChange}
                    value={studentData.act1_score1}
                  />
                  <Input
                    type="date"
                    placeholder="Trial Date"
                    name="act1_try_date1"
                    onChange={handleInputChange}
                    value={studentData.act1_try_date1}
                  />
                  <Input
                    type="text"
                    placeholder="Trial Id"
                    required={false}
                    name="act1_try1_id"
                    onChange={handleInputChange}
                    value={studentData.act1_try1_id}
                  />
                  <Input
                    type="number"
                    placeholder="Number of Copies "
                    name="act1_copy_number"
                    onChange={handleInputChange}
                    value={studentData.act1_copy_number}
                  />
                </>
              )}
              <div className="p-1 border rounded-lg">
                <Input
                  type="checkbox"
                  placeholder="ACT Subject"
                  name="act2"
                  required={false}
                  onChange={handleInputChange}
                  value={studentData.act2}
                />
                {studentData.act2 && (
                  <>
                    <Input
                      type="number"
                      placeholder="ACT subject 1 score"
                      name="act2_score1"
                      onChange={handleInputChange}
                      value={studentData.act2_score1}
                    />
                    <div className="m-2 flex items-center justify-center">
                      <label htmlFor="subject1" className="flex-auto">
                        <span className="text-red-600">*</span>
                        Subject 1:
                      </label>
                      <select
                        className="flex-initial p-2 ml-1 border rounded-xl focus:outline-blue-700"
                        onChange={handleInputChange}
                        name="subject1"
                        value={studentData.subject1}
                      >
                        {ACT_SUBJECTS.map((subject, i) => (
                          <option value={subject.value} key={i}>
                            {subject.text}
                          </option>
                        ))}
                      </select>
                    </div>
                    <Input
                      type="date"
                      placeholder="Trial Date 1"
                      name="act2_try_date1"
                      onChange={handleInputChange}
                      value={studentData.act2_try_date1}
                    />
                    <Input
                      required={false}
                      type="text"
                      placeholder="Trial 1 Id"
                      name="act2_try1_id"
                      onChange={handleInputChange}
                      value={studentData.act2_try1_id}
                    />
                    <Input
                      type="number"
                      placeholder="ACT subject 2 score"
                      name="act2_score2"
                      required={false}
                      onChange={handleInputChange}
                      value={studentData.act2_score2}
                    />
                    <div className="m-2 flex items-center justify-center">
                      <label htmlFor="subject2" className="flex-auto">
                        ACT subject 2:
                      </label>
                      <select
                        className="flex-initial p-2 ml-1 border rounded-xl focus:outline-blue-700"
                        onChange={handleInputChange}
                        name="subject2"
                        value={studentData.subject2}
                      >
                        {ACT_SUBJECTS.map((subject, i) => (
                          <option value={subject.value} key={i}>
                            {subject.text}
                          </option>
                        ))}
                      </select>
                    </div>
                    <Input
                      type="date"
                      required={false}
                      placeholder="Trial Date 2"
                      name="act2_try_date2"
                      onChange={handleInputChange}
                      value={studentData.act2_try_date2}
                    />
                    <Input
                      type="text"
                      placeholder="Trial 2 Id"
                      name="act2_try2_id"
                      onChange={handleInputChange}
                      value={studentData.act2_try2_id}
                      required={false}
                    />
                    <Input
                      type="number"
                      placeholder="ACT subject 3 score"
                      name="act2_score3"
                      required={false}
                      onChange={handleInputChange}
                      value={studentData.act2_score3}
                    />
                    <div className="m-2 flex items-center justify-center">
                      <label htmlFor="subject3" className="flex-auto">
                        ACT subject 3:
                      </label>
                      <select
                        className="flex-initial p-2 ml-1 border rounded-xl focus:outline-blue-700"
                        onChange={handleInputChange}
                        name="subject3"
                        value={studentData.subject3}
                      >
                        {ACT_SUBJECTS.map((subject, i) => (
                          <option value={subject.value} key={i}>
                            {subject.text}
                          </option>
                        ))}
                      </select>
                    </div>
                    <Input
                      placeholder="Trial Date 3"
                      type="date"
                      required={false}
                      name="act2_try_date3"
                      onChange={handleInputChange}
                      value={studentData.act2_try_date3}
                    />
                    <Input
                      type="text"
                      placeholder="Trial 3 Id"
                      name="act2_try3_id"
                      onChange={handleInputChange}
                      value={studentData.act2_try3_id}
                      required={false}
                    />
                    <Input
                      type="number"
                      placeholder="Number of Copies "
                      name="act2_copy_number"
                      onChange={handleInputChange}
                      value={studentData.act2_copy_number}
                    />
                  </>
                )}
              </div>
              <div className="p-1 mt-1 border rounded-lg">
                <Input
                  type="checkbox"
                  placeholder="MOE Authentication"
                  name="moe_auth"
                  required={false}
                  onChange={handleInputChange}
                  value={studentData.moe_auth}
                />
                {studentData.moe_auth && (
                  <Input
                    type="number"
                    placeholder="ACT 1 Number of Copies"
                    name="act1_moe_copy"
                    onChange={handleInputChange}
                    value={studentData.act1_moe_copy}
                  />
                )}
                {studentData.moe_auth & studentData.act2 ? (
                  <Input
                    type="number"
                    placeholder="ACT 2 Number of Copies"
                    name="act2_moe_copy"
                    onChange={handleInputChange}
                    value={studentData.act2_moe_copy}
                  />
                ) : null}
              </div>
            </div>
            <div className="p-1 dir-pthree border rounded">
              <h3 className="text-lg p-2">
                بيانات يجب ادخالها باللغة العربية:
              </h3>
              <Input
                type="text"
                placeholder="الاسم باللغة العربية"
                required={false}
                name="name_in_arabic"
                onChange={handleInputChange}
                value={studentData.name_in_arabic}
              />
              <Input
                type="text"
                placeholder="اسم المدرسة"
                required={false}
                name="school_in_arabic"
                onChange={handleInputChange}
                value={studentData.school_in_arabic}
              />
              <Input
                type="text"
                placeholder="الادارة التعليمية"
                required={false}
                name="educational_administration"
                onChange={handleInputChange}
                value={studentData.educational_administration}
              />
              <Input
                type="text"
                placeholder="اسم مركز الاختبار"
                required={false}
                name="center_test_arabic"
                onChange={handleInputChange}
                value={studentData.center_test_arabic}
              />
              <Input
                type="text"
                placeholder="بلد مركز الاختبار"
                required={false}
                name="center_test_country"
                onChange={handleInputChange}
                value={studentData.center_test_country}
              />
            </div>
          </div>
          <div className="flex flex-row-reverse justify-between">
            <Button
              color="bg-red-500 disabled:bg-gray-300"
              disabled={loading}
              text={
                !loading ? (
                  <>{pathname === "new" ? "Edit and Forward" : "Edit"}</>
                ) : (
                  <Loading />
                )
              }
            />
          </div>
        </form>
      </div>
    </div>
  );
}

function convertToBase64(file) {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);

    fileReader.onload = () => {
      resolve(fileReader.result);
    };

    fileReader.onerror = (error) => {
      reject(error);
    };
  });
}
