export default function Th({ text, bgColor = "bg-gray-200" }) {
  return (
    <th
      scope="col"
      className={`py-3 px-6 text-md font-bold whitespace-nowrap break-words text-left
        tracking-wider text-gray-700 uppercase first-of-type:rounded-tl-lg 
        last-of-type:rounded-tr-lg ${bgColor}`}
    >
      {text}
    </th>
  );
}
