import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { createUser, getUsers } from "../../actions/studensts";
import AlertSuccess from "../../components/AlertSuccess";
import Button from "../../components/Button";
import Input from "../../components/Input";
import Loading from "../../components/Loading";
import UNAUTHORIZED from "../UNAUTHORIZED";

function AddUser() {
  const [userData, setUserData] = useState({
    username: "",
    role: "user", // Default role
    password: "",
  });
  const [users, setUsers] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("profile")).value[0].role;

  const getAllUsers = async () => {
    const { data } = await getUsers();
    if (data) {
      setUsers(data.users);
    }
  };

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      setLoading(true);
      const data = await createUser(userData);
      if (data) {
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
          // navigate("/main");
        }, 2000);
      }
      setLoading(false);
    },
    [userData],
  );

  useEffect(() => {
    getAllUsers();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
  };

  return (
    <div>
      {user !== "admin" ? (
        <UNAUTHORIZED />
      ) : (
        <div>
          <div>
            <h1 className="text-2xl pt-0 pl-6 pb-5 text-white">Create User</h1>
          </div>
          <div className="bg-gray-100 rounded-md m-5 p-5 ">
            <h2 className="text-2xl pb-3">All Users</h2>
            <div className="flex flex-wrap justify-around">
              {users.map((user, key) => {
                return (
                  <div
                    className="bg-white mb-2 border-2 w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 pt-1 p-5 rounded-md"
                    key={key}
                  >
                    <div className="bg-green-400 flex items-center justify-center w-10 h-10 rounded-full">
                      <div>{key + 1}</div>
                    </div>
                    <div>
                      <p>
                        User Name:{" "}
                        <span className="text-xl font-bold text-green-800">
                          {user.username}
                        </span>{" "}
                      </p>
                      <p>
                        User Role:{" "}
                        <span className="text-xl font-bold text-green-800">
                          {user.role}
                        </span>
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="flex items-center justify-center">
            {showAlert && <AlertSuccess text="New user added" />}
            <form
              onSubmit={handleSubmit}
              className="flex flex-col border rounded-lg p-7 shadow bg-white"
            >
              <div className="mb-3">
                <Input
                  name="username"
                  placeholder="Username"
                  type="text"
                  onChange={handleChange}
                />
                <select
                  name="role"
                  value={userData.role}
                  onChange={handleChange}
                  className="w-full p-2 border border-gray-300 rounded mt-2"
                >
                  <option value="admin">Admin</option>
                  <option value="invoice">Invoice</option>
                  <option value="user">User</option>
                </select>
                <Input
                  name="password"
                  placeholder="Password"
                  type="password"
                  onChange={handleChange}
                />
              </div>
              <Button
                type="submit"
                color="bg-green-500 disabled:bg-gray-300"
                disabled={loading}
                text={!loading ? "Add User" : <Loading />}
              />
            </form>
          </div>
        </div>
      )}
    </div>
  );
}

export default AddUser;
