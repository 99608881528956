import * as actionType from "../constants/actionTypes";

const students = (state = [], action) => {
  switch (action.type) {
    case actionType.FETCH_ALL:
      const count = {
        size: action.payload.size,
        maxPage: action.payload.maxPage,
      };
      return { students: action.payload.value, count };
    case actionType.CLEAR:
      return { students: [] };
    default:
      return state;
  }
};

export default students;
