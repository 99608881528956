import { useLocation, useNavigate } from "react-router-dom";
import Button from "../../components/Button";
import { useState } from "react";

export default function ActionButtonsNew() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showDengerAlert, setShowDengerAlert] = useState(false);
  // const [text, setText] = useState("");
  const [, setLoading] = useState(false);
  // const [editLoading, setEditLoading] = useState(false);
  // const url = "https://americanacademyeg.net/api/v2";

  if (showSuccessAlert || showDengerAlert) {
    setTimeout(function () {
      setShowSuccessAlert(false);
      setShowDengerAlert(false);
      setLoading(false);
      navigate("/dashboard/auditing/new");
    }, 2000);
  }

  // const handleForward = async () => {
  //   setLoading(true);
  //   const res = await forword(id, "", "invoice");
  //   if (res.status === 200) {
  //     setShowSuccessAlert(true);
  //     setText(res.data.message);
  //   } else {
  //     setShowDengerAlert(true);
  //     setText(res.data.ERROR);
  //   }
  // };

  // const handleEidtRequest = async () => {
  //   setEditLoading(true);
  //   const res = await requestEdit(id);
  //   if (res.status === 200) {
  //     setShowSuccessAlert(true);
  //     setText(res.data.message);
  //   } else {
  //     setShowDengerAlert(true);
  //     setText(res.data.ERROR);
  //   }
  // };

  return (
    <>
      <div className="py-3 text-center">
        <div className="flex justify-between p-2">
          <div className="p-2">
            <Button
              color="bg-red-500"
              onClick={() => {
                navigate("/main/auditing/new/edit", { state });
              }}
              text="Edit"
            />
          </div>
        </div>
      </div>
    </>
  );
}
