import React, { useCallback, useEffect, useState, useRef } from "react";

import Input from "../components/Input";
import Button from "../components/Button";
import AlertDenger from "../components/AlertDenger";
import Loading from "../components/Loading";
import { useDispatch, useSelector } from "react-redux";

import { login } from "../actions/auth";
import { useNavigate } from "react-router-dom";

const returnUser = (navigate) => {
  try {
    const user = JSON.parse(localStorage.getItem("profile"))?.value[0].role;
    if (user) {
      navigate("/main");
    }
    return;
  } catch (error) {
    return error;
  }
};

export default function Login() {
  const [userData, setUserData] = useState({ username: "", password: "" });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const error = useSelector((state) => state.auth?.error);
  const [showAlert, setShowAlert] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      setLoading(true);
      dispatch(login(userData, navigate));
    },
    [dispatch, navigate, userData],
  );

  const returnUserRef = useRef(returnUser);
  
  useEffect(() => {
    returnUserRef.current = returnUser(navigate);
  }, [navigate]);

  useEffect(() => {
    if (error && !showAlert) {
      setLoading(false);
      setShowAlert(true);
    }
  }, [error, showAlert]);

  const handleChange = (e) =>
    setUserData({ ...userData, [e.target.name]: e.target.value });

  return (
    <div className="min-h-screen flex items-center justify-center">
      {showAlert && (
        <AlertDenger text="Your username or password are incorrect" />
      )}
      <form
        onSubmit={handleSubmit}
        className="flex flex-col border rounded-lg p-7 shadow bg-white"
      >
        <div className="mb-3">
          <Input
            name="username"
            placeholder="Username"
            type="text"
            onChange={handleChange}
          />
          <Input
            name="password"
            placeholder="Password"
            type="password"
            onChange={handleChange}
          />
        </div>
        <Button
          type="submit"
          color="bg-green-500 disabled:bg-gray-300"
          disabled={loading}
          text={!loading ? "Login" : <Loading />}
        />
      </form>
    </div>
  );
}
