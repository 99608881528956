import StudentData from "../components/StudentData";
import Loading from "../components/Loading";

export default function StudentList({
  setSearch,
  search,
  students,
  loading,
  count,
}) {
  const user = JSON.parse(localStorage.getItem("profile")).value[0];
  return (
    <div className="flex flex-col items-center">
      {count && user.role !== "user" ? (
        <span
          className="mt-0 mb-5 p-3 w-14 h-14 flex items-center justify-center 
      rounded-full text-sm text-green-700 bg-green-100"
        >
          {students?.length}
        </span>
      ) : (
        ""
      )}
      <div className="flex justify-between">
        <div>
          <input
            type="search"
            className="bg-white border-2 h-10 pr-5 pl-10 rounded-full text-sm 
                      outline-2 outline-blue-700"
            placeholder="search..."
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            value={search}
          />
        </div>
      </div>
      {loading ? <Loading /> : <StudentData students={students} />}
    </div>
  );
}

