import { useLocation, useNavigate, useParams } from "react-router-dom";
import Button from "../../components/Button";

export default function ActionButtonsEdit() {
  const navigate = useNavigate();
  const state = useLocation().state;
  const { id } = useParams();

  return (
    <div className="flex justify-between">
      <div className="p-4">
        <Button
          color="bg-red-500"
          onClick={() => {
            navigate("/main/auditing/request_edit/edit", { state: state });
          }}
          text="Edit"
        />
      </div>
      <div className="p-4">
        <Button
          color="bg-green-500"
          onClick={() => {
            navigate("/main/auditing/request_edit/mail", { state: id });
          }}
          text="Send an email"
        />
      </div>
    </div>
  );
}
