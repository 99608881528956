import * as actionType from "../constants/actionTypes";

const authReducer = (state = { authData: null }, action) => {
  switch (action.type) {
    case actionType.LOGIN:
      if (!action.error)
        localStorage.setItem("profile", JSON.stringify({ ...action?.data }));
      return { ...state, authData: action.data, error: action.error };
    case actionType.LOGOUT:
      localStorage.clear();
      return null;
    default:
      return state;
  }
};

export default authReducer;
