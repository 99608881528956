import { writeFile, utils, write } from "xlsx";
import {
  exportAllExcel,
  exportExcelState,
  exportExcelMoe,
  exportExcelFinalMoe,
  exportExcelAct1,
  exportExcelAct2
} from "../actions/studensts";

export async function exportToExcel(selectState) {
  if (selectState === "all") {
    // console.log("here will setup exportAllToExcel");
    const { data } = await exportAllExcel();
    const all_student = data.data;
    const workSheet = utils.json_to_sheet(all_student);
    const workBook = utils.book_new();
    utils.book_append_sheet(workBook, workSheet, "all_student");
    write(workBook, { bookType: "xlsx", type: "buffer" });
    write(workBook, { bookType: "xlsx", type: "binary" });
    writeFile(workBook, "all_student.xlsx");
  } else {
    //  console.log("here will setup exportToExcelWithState");
    const { data } = await exportExcelState(selectState);
    const stateFilter = data.data;
    const workSheet = utils.json_to_sheet(stateFilter);
    const workBook = utils.book_new();
    utils.book_append_sheet(workBook, workSheet, "student_state");
    write(workBook, { bookType: "xlsx", type: "buffer" });
    write(workBook, { bookType: "xlsx", type: "binary" });
    writeFile(workBook, `${"student_state_" + selectState + ".xlsx"}`);
  }
}

export async function excelMoe() {
  const { data } = await exportExcelMoe();
  const moe_student = data.data;
  const workSheet = utils.json_to_sheet(moe_student);
  const workBook = utils.book_new();
  utils.book_append_sheet(workBook, workSheet, "moe_student");
  write(workBook, { bookType: "xlsx", type: "buffer" });
  write(workBook, { bookType: "xlsx", type: "binary" });
  writeFile(workBook, "moe_student.xlsx");
}

export async function excelFinalMoe() {
  const { data } = await exportExcelFinalMoe();
  const final_student = data.data;
  const workSheet = utils.json_to_sheet(final_student);
  const workBook = utils.book_new();
  utils.book_append_sheet(workBook, workSheet, "final_student");
  write(workBook, { bookType: "xlsx", type: "buffer" });
  write(workBook, { bookType: "xlsx", type: "binary" });
  writeFile(workBook, "final_student.xlsx");
}

export async function excelActOne() {
  const { data } = await exportExcelAct1();
  const act1_student = data.data;
  const workSheet = utils.json_to_sheet(act1_student);
  const workBook = utils.book_new();
  utils.book_append_sheet(workBook, workSheet, "act1_student");
  write(workBook, { bookType: "xlsx", type: "buffer" });
  write(workBook, { bookType: "xlsx", type: "binary" });
  writeFile(workBook, "act1_student.xlsx");
}

export async function excelActTwo() {
  const { data } = await exportExcelAct2();
  const act2_student = data.data;
  const workSheet = utils.json_to_sheet(act2_student);
  const workBook = utils.book_new();
  utils.book_append_sheet(workBook, workSheet, "act2_student");
  write(workBook, { bookType: "xlsx", type: "buffer" });
  write(workBook, { bookType: "xlsx", type: "binary" });
  writeFile(workBook, "act2_student.xlsx");
}
