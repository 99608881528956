import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import RedirectCard from "../../components/RedirectCard";

export default function Redirect() {
  const request = useLocation().state?.request;
  const navigate = useNavigate();

  useEffect(() => {
    if (!request) {
      navigate("/form");
    }
  }, [request,navigate]);

  if (request) {
    setTimeout(() => {
      window.location.href = "https://www.facebook.com/AATCExams/";
    }, 2000);
  }

  return (
    <div className="min-h-screen flex items-center justify-center">
      <RedirectCard request={request} />
    </div>
  );
}
