import { useNavigate, useParams } from "react-router-dom";
import Button from "../../components/Button";

export default function ActionButtonsNew() {
  const navigate = useNavigate();
  const { id } = useParams();
  return (
    <div className="flex flex-row-reverse justify-between">
      <Button
        color="bg-red-500"
        onClick={() => {
          navigate("/main/invoice/new/mail", { state: id });
        }}
        text="Send to Wait"
      />
    </div>
  );
}
