export default function Pagination({ page, setPage, maxPage, nextDisabled }) {
  const onChange = (e) => {
    const value = e.target.value;
    if (parseInt(value) <= 0 || parseInt(value) > maxPage || value === "") {
      return;
    }
    setPage(parseInt(value));
  };

  const onFocus = (e) => e.target.select();

  return (
    <div className="w-full my-1 flex items-center justify-center">
      <button
        className="border bg-white rounded-lg py-2.5 px-5 focus:outline 
          outline-2 outline-blue-500 disabled:text-gray-200 text-sm"
        disabled={page === 1 && true}
        onClick={() => setPage(page - 1)}
      >
        Previous
      </button>
      <input
        className="py-2.5 px-5 mx-2 w-20 text-sm border rounded-lg bg-white outline-blue-600"
        onChange={onChange}
        type="number"
        value={page}
        onFocus={onFocus}
      ></input>
      <button
        className="border bg-white rounded-lg py-2.5 px-5 focus:outline 
          outline-2 outline-blue-600 disabled:text-gray-100 text-sm"
        disabled={nextDisabled}
        onClick={() => {
          setPage(page + 1);
        }}
      >
        Next
      </button>
      <span className="text-sm text-white text-center pl-3">
        max page <br />
        <span> {maxPage}</span>
      </span>
    </div>
  );
}
