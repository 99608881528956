import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { getType } from "../../actions/studensts";
import UNAUTHORIZED from "../UNAUTHORIZED";
import StudentList from "../StudentList";
import { excelActOne, excelActTwo } from "../exportToExcel";

export default function ScoreHandler() {
  const dispatch = useDispatch();
  const location = useLocation();
  const studentType = useLocation().pathname.split("/")[3];
  const { students } = useSelector((state) => state.students);
  const [search, setSearch] = useState("");
  // const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  // const [showDengerAlert, setShowDengerAlert] = useState(false);
  // const [text, setText] = useState("");
  // const [loading1, setLoading1] = useState(false);
  const [loading, setLoading] = useState(false);
  const count = useSelector((state) => state.students.count);
  const {role, username} = JSON.parse(localStorage.getItem("profile")).value[0];

  // if (showSuccessAlert || showDengerAlert) {
  //   setTimeout(function () {
  //     setShowSuccessAlert(false);
  //     setShowDengerAlert(false);
  //     setLoading1(false);
  //   }, 2000);
  // }

  // const handleMail = async () => {
  //   setLoading1(true);
  //   const res = await sendScore();
  //   if (res.status === 200) {
  //     setShowSuccessAlert(true);
  //     setText(res.data.message);
  //   } else {
  //     setShowDengerAlert(true);
  //     setText(res.data.ERROR);
  //   }
  // };

  useEffect(() => {
    const state = convertType(studentType);
    const timeOutId = setTimeout(
      () => dispatch(getType("scorehandler", state, search)),
      500,
    );

    return () => clearTimeout(timeOutId);
  }, [location, search, dispatch, studentType]);

  useEffect(() => {
    if (students) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [students]);

  return (
    <div>
      {role !== "user" && role !== "admin" ? (
        <UNAUTHORIZED />
      ) : (
        <div>
          <div className="flex flex-wrap pt-0 pl-6 pb-10 justify-between">
            <h1 className="text-2xl  text-white">
              {studentType === "new" ? (
                "Score Request"
              ) : (
                <>
                  {studentType === "score_pending" ? (
                    "Score Pending"
                  ) : (
                    <>
                      {studentType === "score_validation"
                        ? "Score Validation"
                        : null}
                    </>
                  )}
                </>
              )}
            </h1>
            {studentType === "new" &&( username === "asmaa"|| username === "nour"|| username === "abdelrhman") && 
              <div className="flex">
                <div className="px-4">
                  <button
                    type="button"
                    className="text-white bg-green-900 hover:bg-green-800 p-2 
                        focus:ring-4 focus:ring-green-400 font-medium rounded-lg text-sm text-center cursor-pointer"
                    onClick={excelActOne}
                  >
                    Export Act1 Students
                  </button>
                </div>
                <div className="px-4">
                  <button
                    type="button"
                    className="text-white bg-green-900 hover:bg-green-800 p-2 
                        focus:ring-4 focus:ring-green-400 font-medium rounded-lg text-sm text-center cursor-pointer"
                    onClick={excelActTwo}
                  >
                    Export Act2 Students
                  </button>
                </div>
              </div>}
          </div>
          <div className="flex justify-center">
            <div
              className="lg:w-3/5 md:w-3/4 w-full min-h-screen border shadow
    flex flex-col bg-white p-6 rounded-lg"
            >
              <StudentList
                students={students}
                setSearch={setSearch}
                search={search}
                loading={loading}
                count={count}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

function convertType(studentType) {
  switch (studentType) {
    case "score_validation":
      return "score_validation";
    case "new":
      return "score_request";
    case "score_pending":
      return "score_pending";
    default:
      return "score_validation";
  }
}
