import Button from "../../components/Button";
import { useLocation, useParams } from "react-router-dom";
import { forwordwithcount } from "../../actions/studensts";
import { useState } from "react";
import AlertDenger from "../../components/AlertDenger";
import AlertSuccess from "../../components/AlertSuccess";
import Loading from "../../components/Loading";

export default function ActionButtonForward() {
  const user = JSON.parse(localStorage.getItem("profile")).value[0].username;
  const { id } = useParams();
  const pathname = useLocation().pathname.split("/")[3];
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showDengerAlert, setShowDengerAlert] = useState(false);
  const [text, setText] = useState("");
  const [loading, setLoading] = useState(false);

  if (showSuccessAlert || showDengerAlert) {
    setTimeout(function () {
      setShowSuccessAlert(false);
      setShowDengerAlert(false);
      setLoading(false);
      if (pathname === "new") {
        // navigate(`/main/invoice/new`);
      } else {
        // navigate(`/main/invoice/${pathname}`);
      }
    }, 2000);
  }

  const handleForward = async () => {
    setLoading(true);
    if (pathname === "new") {
      const res = await forwordwithcount("score_validation", id, user, false);
      if (res.status === 200) {
        setShowSuccessAlert(true);
        setText(res.data.message);
      } else {
        setShowDengerAlert(true);
        setText(res.data.ERROR);
      }
    } else if (pathname === "wait") {
      const res = await forwordwithcount("score_validation", id, user, false);
      if (res.status === 200) {
        setShowSuccessAlert(true);
        setText(res.data.message);
      } else {
        setShowDengerAlert(true);
        setText(res.data.ERROR);
      }
    }
  };

  return (
    <div className="flex flex-row-reverse justify-between">
      {showSuccessAlert && <AlertSuccess text={text} />}
      {showDengerAlert && <AlertDenger text={text} />}
      <Button
        color="bg-green-500 disabled:bg-gray-300"
        onClick={handleForward}
        disabled={loading}
        text={!loading ? "Forward" : <Loading />}
      />
    </div>
  );
}
