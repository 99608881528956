export const ACT_SUBJECTS = [
  { value: "", text: "" },
  { value: "Math 1", text: "Math 1" },
  { value: "Math 2", text: "Math 2" },
  { value: "English 1", text: "English 1" },
  { value: "English 2", text: "English 2" },
  { value: "Physics", text: "Physics" },
  { value: "Biology", text: "Biology" },
  { value: "Chemistry", text: "Chemistry" },
];

export const FIRST_ACT_SUBJECTS = [
  { value: "Math 1", text: "Math 1" },
  { value: "Math 2", text: "Math 2" },
  { value: "English 1", text: "English 1" },
  { value: "English 2", text: "English 2" },
  { value: "Physics", text: "Physics" },
  { value: "Biology", text: "Biology" },
  { value: "Chemistry", text: "Chemistry" },
];

export const EXAM_NAMES = [
  "ACT1 One Trial Certificate",
  "ACT1 Super Score Certificate",
  "ACT1 Matching Certificate",
];
