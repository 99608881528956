import { LOGIN } from "../constants/actionTypes";
import * as api from "../api/index";

export const login = (userData, navigate) => async (dispatch) => {
  try {
    const { data } = await api.logIn(userData);

    dispatch({ type: LOGIN, data, error: null });
    const user = data.value[0].role;

    if (!user) {
      navigate("/");
      return;
    }

    if (user) {
      navigate("/main");
      return;
    }
  } catch (error) {
    dispatch({ type: LOGIN, data: null, error });
  }
};
