import { useLocation } from "react-router-dom";
import { getType } from "../../actions/studensts";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import StudentList from "../StudentList";
import UNAUTHORIZED from "../UNAUTHORIZED";

export default function Auditing() {
  const studentType = useLocation().pathname.split("/")[3];
  const dispatch = useDispatch();
  const location = useLocation();
  const { students } = useSelector((state) => state.students);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const count = useSelector((state) => state.students.count);
  const user = JSON.parse(localStorage.getItem("profile")).value[0].role;

  useEffect(() => {
    const state = convertType(studentType);
    const timeOutId = setTimeout(
      () => dispatch(getType("auditing", state, search)),
      500,
    );
    return () => clearTimeout(timeOutId);
  }, [studentType, location, search, dispatch]);

  useEffect(() => {
    if (students) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [students]);

  return (
    <div>
      {user !== "user" && user !== "admin" ? (
        <UNAUTHORIZED />
      ) : (
        <div>
          <h1 className="text-2xl pt-0 pl-6 pb-5 text-white">
            {studentType === "new" ? "New In Auditing" : "Request Edit"}
          </h1>
          <div className="flex justify-center">
            <div
              className="lg:w-3/5 md:w-3/4 w-full min-h-screen border shadow
          flex flex-col bg-white p-6 rounded-lg"
            >
              <StudentList
                setSearch={setSearch}
                students={students}
                search={search}
                loading={loading}
                count={count}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

function convertType(studentType) {
  switch (studentType) {
    case "new":
      return "auditing";
    case "request_edit":
      return "request_edit";
    default:
      return "auditing";
  }
}
