import { useLocation } from "react-router-dom";

import ActionButtonsNew from "./ActionButtonsNew";

export default function ActionButtons() {
  const userType = useLocation().pathname.split("/")[3];

  return (
    <>{userType === "new" ? <ActionButtonsNew /> : ""}</>
  );
}
